export const eventFilterOptions = [
    {
        label: "All",
        value: "All",
    },
    {
        label: "Availability",
        value: "Availability",
    },
    {
        label: "Badging",
        value: "Badging",
    },
    {
        label: "Buy Box",
        value: "Buy Box",
    },
    {
        label: "Feedback",
        value: "Feedback",
    },
    // {
    //     label: "Fulfillment",
    //     value: "Fulfillment",
    // },
    {
        label: "Pricing",
        value: "Pricing",
    },
    {
        label: "Variation",
        value: "Variation",
    },
]
