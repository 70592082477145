import React, { Dispatch, FC, SetStateAction } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";

import MDrawer from "../../../../Atoms/MDrawer";
import MTypography from "../../../../Atoms/MTypography";

import classes from "./index.module.css";
import EventFilters from "../EventFilters";
import { eventFilterOptions } from "../config";
import KeyboardTabRoundedIcon from "@mui/icons-material/KeyboardTabRounded";
import { Divider, IconButton } from "@mui/material";
import EventCardContainer from "../EventCardContainer";
import { useDeviceType } from "../../../../../hooks/useDeviceType";
import ProductEventsWaitingCards from "../../../../Molecules/AnimatedSkeletonCard/ProductEventsWaitingCards";
import { ProductEventsListInterface, ProductEvent } from "../../../../../apis/types/generate-product";
import { capitalizeFirstLetter } from "../../../../../utils/helpers/strings";

interface EventsDrawerProps {
  open: boolean;
  onClose: () => void;
  showEventFilters: any;
  setShowEventFilters: any;
  anchorEl: any;
  handleFilterClick: any;
  isLoading: boolean;
  productEventsList: ProductEventsListInterface;
  handleApplyFilter: (filterTag: string) => void;
  selectedRadio: string;
  setSelectedRadio: Dispatch<SetStateAction<string>>;
}

const EventsDrawer: FC<EventsDrawerProps> = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    setShowEventFilters,
    showEventFilters,
    handleFilterClick,
    isLoading,
    handleApplyFilter,
    productEventsList,
    selectedRadio,
    setSelectedRadio,
  } = props;
  const { isDesktop } = useDeviceType();

  return (
    <>
      <MDrawer open={open} onClose={onClose}>
        <div className={classes.DrawerContainer}>
          <div className={`${isDesktop ? classes.TitleBarWithFilterDrawer : classes.TitleBarWithFilterDrawerM}`}>
            <MTypography variant="body1" customClass={classes.ProdcutEventsTitleDrawer}>
              Product Events
            </MTypography>
            <div className={`d-flex align-items-center gap-3 justify-content-between`}>
              <div
                className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer HoverEffectForButton`}
                onClick={handleFilterClick}
              >
                <FilterListIcon fontSize={"medium"} />
                <MTypography variant={"subtitle1"} color="inherit">
                  {"Filters"}
                </MTypography>
              </div>
              <EventFilters
                open={showEventFilters}
                onClose={() => setShowEventFilters(false)}
                options={eventFilterOptions}
                anchorEl={anchorEl}
                handleApplyFilter={handleApplyFilter}
                selectedRadio={selectedRadio}
                setSelectedRadio={setSelectedRadio}
              />
              <IconButton onClick={onClose}>
                <KeyboardTabRoundedIcon htmlColor="#80828A" />
              </IconButton>
            </div>
          </div>
          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.5)" }} />
          <div className={`${isDesktop ? classes.EventCardsContainer : classes.EventCardsContainerM}`}>
            {isLoading ? (
              <ProductEventsWaitingCards />
            ) : (
              <>
                {Object.values(productEventsList)?.some((items: any[]) => items.length > 0) ? (
                  Object.entries(productEventsList || {})?.map(([key, items]: any) => (
                    <div key={key}>
                      {items.length ? (
                        <>
                          <div className="mb-0">
                            <MTypography variant="body2" customClass={classes.Title}>
                              {capitalizeFirstLetter(key || "")}
                            </MTypography>
                          </div>

                          {items.map((event: ProductEvent, index: number) => (
                            <div key={index}>
                              <EventCardContainer event={event} isActive={key === "today"} />
                            </div>
                          ))}
                          <Divider sx={{ my: "16px", opacity: 1 }} />
                        </>
                      ) : (
                        <div />
                      )}
                    </div>
                  ))
                ) : (
                  <MTypography variant="subtitle1" customClass={classes.NoEventsAvailable}>
                    There are currently no events for this product.
                  </MTypography>
                )}
              </>
            )}
          </div>
        </div>
      </MDrawer>
    </>
  );
};

export default EventsDrawer;
