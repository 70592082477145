import React, { FC } from "react";
import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";
import classes from "./index.module.css";
import { EventsBadgesEnum } from "../../../../../utils/constants/enums";

interface ProductEventBadgeProps {
  badgeTitle: EventsBadgesEnum;
}

const ProductEventBadge: FC<ProductEventBadgeProps> = (props) => {
  const { badgeTitle = "" } = props;
  return (
    <div className={`${classes.BadgeContainer} ${classes[capitalizeEachLetter(badgeTitle?.replace(" ", ""))]}`}>
      {badgeTitle}
    </div>
  );
};

export default ProductEventBadge;
