import { EventsBadgesEnum, EventsEnum } from "../../../../../utils/constants/enums";

export const getBadgeForEvent = (eventTitle: EventsEnum) => {
  if (eventTitle === EventsEnum.BUY_BOX) {
    return EventsBadgesEnum.Buy_Box;
  } else if (
    eventTitle === EventsEnum.IS_PRIME ||
    eventTitle === EventsEnum.BEST_SELLER_BADGE ||
    eventTitle === EventsEnum.LIMITED_DEAL_BADGE ||
    eventTitle === EventsEnum.COUPON_TEXT_BADGE
  ) {
    return EventsBadgesEnum.Badging;
  } else if (eventTitle === EventsEnum.AMAZON_CHOICE) {
    return EventsBadgesEnum.Badging
  } else if (eventTitle === EventsEnum.COMPETITORS_PRICE || eventTitle === EventsEnum.PRICE) {
    return EventsBadgesEnum.Pricing;
  } else if (eventTitle === EventsEnum.INVENTORY) {
    return EventsBadgesEnum.Availability;
  } else if (
    eventTitle === EventsEnum.NEGATIVE_REVIEWS ||
    eventTitle === EventsEnum.NEGATIVE_REVIEWS_ON_PAGE ||
    eventTitle === EventsEnum.RATING
  ) {
    return EventsBadgesEnum.Feedback;
  } else if (eventTitle === EventsEnum.VARIANTS) {
    return EventsBadgesEnum.Variation;
  } else if (eventTitle === EventsEnum.FASTEST_DELIVERY_DAYS || eventTitle === EventsEnum.STANDARD_DELIVERY_DAYS) {
    return EventsBadgesEnum.Fulfillment;
  } else {
    return EventsBadgesEnum.Badging;
  }
};
