import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { configureEventsResponse } from "./config";
import { generateProductApis } from "../../../../apis/generate-product";
import { CompleteProductSelector } from "../../../../store/product/productDetails/productDetails.selectors";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { setProductEventsHistoryDispatch, setProductEventsHistoryMessageDispatch } from "../../../../store/product/productDetails/productDetails.actions";
import { useDispatch } from "react-redux";
import { transformProductEventsHistoryResponse } from "../../../../utils/helpers/apis";

const Event = (event: any): any => {
  const { isMobile } = useDeviceType();
  return (
    <div
      style={{
        background: event.event.color,
        borderRadius: "2px",
        padding: "2px 5px",
        cursor: "pointer",
        margin: isMobile ? "" : "0 4px",
        marginTop: isMobile ? "" : "2px",
        display: "flex",
      }}
    >
      {event?.event.title}
    </div>
  );
};

const ReactBigCalendar: React.FC = () => {
  moment.locale("en-GB");

  const [events, setEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()

  const completedProduct = useSelector(CompleteProductSelector);

  const localizer = momentLocalizer(moment);

  const { isMobile } = useDeviceType();

  const getEvents = async ({ startDate, endDate }: any) => {
    try {
      setIsLoading(true);
      const events = await generateProductApis.getProductEventsHistories({
        productAsin: completedProduct.productASIN,
        userID: completedProduct?.userID,
        startDate: startDate,
        endDate: endDate,
      });

      const transformedData = transformProductEventsHistoryResponse(events)
      dispatch(setProductEventsHistoryDispatch(transformedData))
      setEvents(events);
      setIsLoading(false);
    } catch (e) {
      console.log("error getEvents", e);
      setIsLoading(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {

    dispatch(
      setProductEventsHistoryMessageDispatch({
        titleText: "Showing events for a month.",
        noEventsText: "There are no events for this product in this month.",
      })
    );

    const startDate = moment().startOf("month").clone().startOf("week");
    const endDate = moment().endOf("month").clone().endOf("week");

    getEvents({
      startDate,
      endDate,
    }).then((res: any) => console.log(res));
  }, []);
  const onRangeChange = (range: any) => {
    getEvents({
      startDate: range.start,
      endDate: range.end,
    }).then((res: any) => console.log(res));
  };

  const getEventStyles = () => {
    var style = {
      backgroundColor: "red !important",
    };
    return {
      style: style,
    };
  };
  console.log(Event);

  return (
    <div style={{ height: isMobile ? 700 : 550 }}>
      <div
        style={{
          height: "20px",
        }}
      >
        {isLoading && <LinearProgress />}
      </div>
      <Calendar
        components={{
          eventWrapper: Event,
        }}
        views={{
          month: true,
          week: false,
          day: false,
          agenda: false,
        }}
        style={{ height: "100%", paddingBottom: "20px" }}
        onRangeChange={onRangeChange}
        localizer={localizer}
        events={configureEventsResponse(events || [], isMobile)}
        step={60}
        defaultDate={new Date()}
        startAccessor="start"
        endAccessor="end"
        popup
        onShowMore={(events: any, date: any) => {
          console.log(events, date);
        }}
        eventPropGetter={() => getEventStyles()}
      />
    </div>
  );
};

export default ReactBigCalendar;
