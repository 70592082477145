import React, { Dispatch, SetStateAction } from "react";
import { FormControl, FormControlLabel, Grid, Menu, Radio, RadioGroup, Typography } from "@mui/material";
import classes from "./index.module.css";
import MButton from "../../../../Atoms/MButton";

interface DefaultMenu {
  label: string;
  value: any;
}
interface Props {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  options: DefaultMenu[];
  handleApplyFilter: (filterTag: string) => void
  selectedRadio: string;
  setSelectedRadio: Dispatch<SetStateAction<string>>
}

const EventFilters: React.FC<Props> = (props) => {
  const { open, anchorEl, onClose, options, handleApplyFilter, selectedRadio, setSelectedRadio } = props;
  
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };

  const handleCancle = () => {
    onClose();
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ padding: "8px 8px" }}
      >
        <FormControl component="fieldset">
          <Typography variant="h6" className={`${classes.Headers} pt-3 px-3 pb-2`}>
            Event Filters
          </Typography>
          <RadioGroup
            aria-label="publish-status"
            name="publish-status"
            value={selectedRadio}
            onChange={(e) => {
              handleRadioChange(e);
            }}
            sx={{ paddingLeft: "16px" }}
          >
            <Grid container sx={{ maxWidth: "300px" }}>
              {options?.map((mItem: DefaultMenu, index: number) => (
                <Grid item xs={12} md={6} key={index}>
                  <FormControlLabel
                    key={mItem.value}
                    value={mItem.value}
                    control={<Radio />}
                    label={mItem.label}
                    sx={{ height: "24px", mb: "10px" }}
                    classes={{
                      label: selectedRadio === mItem.label ? classes.ActiveLabel : classes.NonActiveLabel,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
        <div className={`d-flex justify-content-end gap-2 px-3 mw-100 pt-4 pb-1 mt-3 ${classes.ButtonsContainer}`}>
          <MButton variant="outlined" size={"small"} onClick={handleCancle}>
            Cancel
          </MButton>
          <MButton size={"small"} onClick={() => handleApplyFilter(selectedRadio)}>
            Apply Filters
          </MButton>
        </div>
      </Menu>
    </>
  );
};

export default EventFilters;
