import { BottomNavigation, Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import "./keywords.style.scss";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PRIORITISE_KEYWORDS,
  URL_PRODUCTS,
  URL_LANDING_PAGE,
  URL_PRODUCT_FETCHED,
  URL_PRODUCT_CATALOG,
} from "../../../routes/routes-path";
import AppBar from "../../../components/Molecules/AppBar";
import KeywordSkeleton from "../../../components/Organisms/Keywords/KeywordSkeleton/KeywordSkeleton";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import MTypography from "../../../components/Atoms/MTypography";
import CrossElement from "../../../components/Molecules/AppBar/crossElement";

import { camelCaseToNormalSentence } from "../../../utils/helpers/strings";
import { DataTableRef } from "../../../interfaces/datatable";
import {
  ERR_KEYWORDS_NOT_FOUND,
  UnlockGreaterPossibilities,
  UserReachedRegenLimit,
} from "../../../utils/constants/messages/errors";
import { toast } from "react-toastify";
import { generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription } from "../../../apis/product";
import { navigatePage } from "../../../utils/helpers/common";
import { KeywordsApis, updateSelectedKeywordsAgainstProduct } from "../../../apis/keywords";
import CenterElement from "../../../components/Molecules/AppBar/centerElement";
import {
  CatalogEnum,
  PagesEnum,
  ParamsEnum,
  ProductFetchType,
  RolesEnum,
  TabsEnum,
} from "../../../utils/constants/enums";
import { generateProductApis } from "../../../apis/generate-product";
import { AppState } from "../../../store/reducers";
import { ApiRetries, errorHandler, isBadGateway, sleep } from "../../../utils/helpers/apis";
import { SelectedKeywordsSelector } from "../../../store/product/product.selector";
import {
  getPhrase,
  resetSelectedPrioritizeKeywordsDispatch,
  setAsinKeywordHistoryAction,
  setSelectedKeywordsDispatch,
  setSellingKeywordsDispatch,
} from "../../../store/product/product.actions";
import {
  createKeywordObject,
  doAndDontKeywordPage,
  generateSelectionModal,
  isCardActive,
  minKeywordsRequired,
  CountTooltip,
  RelevancyTooltip,
  SearchVolumeTooltip,
} from "./config";
import { useDialog } from "../../../components/Atoms/Dialog/useDialog";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { deepCompareArrays, findKeywordsInRows } from "../../../utils/helpers/general-helpers";
import Donut from "../../../assets/svgs/ProductFetchSteps/Mobile/Donut3.svg";
import FetchProductHeader from "../../../components/Molecules/FetchProductHeader";

import classes from "./index.module.css";
import { formatNumberWithCommas } from "../../../utils/helpers/numbers";
import KeywordCardM from "../../../components/Organisms/Keywords/KeywordCardM";
import DataTable from "../../../components/mui/dataGrid";
import MButton from "../../../components/Atoms/MButton";
import KeywordsPageDialoge from "../../../components/mui/dialogebox/keywordsPageDialoge";
import FetchProductDetails from "../../../components/Organisms/FetchProductDetails";
import { DbUserSelector } from "../../../store/user/user.selector";
import { ScrapeProductSelector } from "../../../store/product/productScrape/productScrape.selectors";
import { userRole as userRoles } from "../../../utils/helpers/priviligesChecks";
import ActionBox from "../../../components/Molecules/ActionBox";
import MTextField from "../../../components/Atoms/MTextField";
import { isAxiosError } from "axios";
import {
  checkIsCompanyReachedHeliumOrContentRegenLimit,
  checkIsProductOwnerReachedHisLimit,
} from "../../SavedProducts/config";
import ToastError from "../../../components/Atoms/ToastError";
import KeywordsCardSkeletonM from "../../../components/Molecules/AnimatedSkeletonCard/KeywordsCardSkeletonM";
import NoResultsFound from "../../../components/Atoms/NoResultsFound/NoResultsFound";

interface KeywordsPageState {
  showUnSelected: boolean;
  loading: boolean;
  columns: GridColDef[];
  rows: { [key: string]: any };
  selectedRows: { [key: string]: any }[];
  searchVolume: number;
  selectionModel: GridSelectionModel;
  openErrorBox: boolean;
  errorMessage: string;
  keywordGenerationLimit: boolean;
}

const KeywordsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(DbUserSelector);
  const scrapeProduct = useSelector(ScrapeProductSelector);
  // const changeKeywordOwnerId = useSelector(ChangeKeywordProductOwnerId)
  const changeKeywordOwnerId = localStorage.getItem("productOwnerId") ? localStorage.getItem("productOwnerId") : null;

  const competitorsUpdated = scrapeProduct?.competitorsUpdated;
  const competitorsASIN = scrapeProduct?.selectedCompetitors?.map((comp) => comp.asin);
  const fetchPayload = competitorsUpdated
    ? {
        productASIN: params[ParamsEnum.ASIN] || "",
        competitorsASIN,
        userID: changeKeywordOwnerId ? changeKeywordOwnerId : currentUser?._id,
      }
    : {
        productASIN: params[ParamsEnum.ASIN] || "",
        userID: changeKeywordOwnerId ? changeKeywordOwnerId : currentUser?._id,
      };

  const domain = params?.domain || "";

  const { isMobile, isDesktop } = useDeviceType();

  let selectedKeywords = useSelector(SelectedKeywordsSelector);

  const tableRef = useRef<DataTableRef>({} as DataTableRef);

  const { isOpen, onClose, onOpen } = useDialog();

  const currentAsin = params[ParamsEnum.ASIN];

  const [state, setState] = useState<KeywordsPageState>({
    showUnSelected: false,
    loading: false,
    columns: [],
    rows: [],
    selectedRows: [],
    searchVolume: 0,
    selectionModel: [],
    keywordGenerationLimit: true,
    openErrorBox: false,
    errorMessage: "",
  });

  const [isDoAndDont, setIsDoAndDont] = useState<boolean>(true);
  const [allKeywordRows, setAllKeywordRows] = useState<{ [key: string]: any }[]>([]);

  const userRole = useSelector((state: AppState) => state.user.role);
  const [retryApi, setRetryApi] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [refetchKeywords, setRefetchKeywords] = useState<number>(0);
  const toggleDoAndDont = () => {
    setIsDoAndDont(!isDoAndDont);
  };

  const dispatchSelectRows = (rows: any): void => {
    dispatch(setSelectedKeywordsDispatch(rows));
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const retryData = async () => {
      await sleep(20000);
      await fetchKeyword();
    };
    if (retryApi && retryApi <= ApiRetries) {
      retryData();
    }
  }, [retryApi]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSearchFilter();
  }, [search]);

  const fetchKeyword = async () => {
    let checkLimit = true;
    if (competitorsUpdated) {
      try {
        const count = await KeywordsApis.getHelium10BalanceCount();
        if (userRoles.isAutomatoUser(currentUser.email)) {
          // toast.info(`Usage counter: ${count}`);
          console.log(count);
        }
      } catch (e) {
        checkLimit = false;
        toast.error(errorHandler(e));
      }
    }

    if (checkLimit) {
      try {
        const resp = await KeywordsApis.getCompetitorsKeywords(fetchPayload);
        const data: {
          competitorsReport: any;
          reportStatus: "SUCCESS" | "FAILURE";
          selectedHelium10Keywords: any[];
        } = resp.data.data;

        if (data.reportStatus === "SUCCESS") {
          navigate("", {
            replace: true,
            state: { ...location.state, prevCompetitorsASIN: location.state?.newCompetitorsASIN }, // Replace with your desired state data
          });
          if ("sellingKeywords" in resp.data.data && selectedKeywords.length < 1) {
            dispatch(setSellingKeywordsDispatch(resp.data.data.sellingKeywords));
          }
          if (
            selectedKeywords?.length < 1 &&
            Array.isArray(data.selectedHelium10Keywords) &&
            data.selectedHelium10Keywords !== null &&
            data.selectedHelium10Keywords !== undefined &&
            data.selectedHelium10Keywords.length > 0
          ) {
            selectedKeywords = data.selectedHelium10Keywords;
          }

          let columns: any[] = [];
          const objectAsin: string = params[ParamsEnum.ASIN] || "";
          Object.keys(data.competitorsReport[0]).map((column) => {
            if (
              column !== "blacklistedWord" &&
              column !== "competitorsUsedForCompetitiveness" &&
              column !== "count" &&
              column !== "usability" &&
              column !== "brandFilterPercentage" &&
              column !== "buyingIntent" &&
              (!column.startsWith("B0") || column === params[ParamsEnum.ASIN])
            ) {
              columns.push({
                field: column,
                headerName: column === params[ParamsEnum.ASIN] ? "Your ASIN" : camelCaseToNormalSentence(column),
                flex: column === "phrase" ? 2 : 1,
                sortingOrder: ["desc", "asc"],
                sortable: true,
                disableColumnMenu: true,
                renderHeader: (par: GridColumnHeaderParams) => (
                  <Tooltip
                    title={
                      column === "searchVolume"
                        ? SearchVolumeTooltip
                        : column === "relevancy"
                        ? RelevancyTooltip
                        : column === "count"
                        ? CountTooltip
                        : column === "competitiveness"
                        ? "Rank Ease"
                        : column === params[ParamsEnum.ASIN]
                        ? column
                        : par.colDef.headerName
                    }
                    arrow
                    placement="right"
                  >
                    <Typography>
                      {column === params[ParamsEnum.ASIN]
                        ? "Your Rank"
                        : column === "count"
                        ? "Indexing Competitors"
                        : column === "competitiveness"
                        ? "Rank Ease"
                        : camelCaseToNormalSentence(column)}
                    </Typography>
                  </Tooltip>
                ),
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <Grid className={"overflow-hidden cursor-pointer"}>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                        title={params.value}
                        className={
                          params.row?.blacklistedWord
                            ? "kw-relevancy gray"
                            : column === "relevancy"
                            ? params.value >= 90
                              ? "kw-relevancy green"
                              : params.value >= 80 && params.value <= 89
                              ? "kw-relevancy light-green"
                              : "kw-relevancy"
                            : column === "searchVolume"
                            ? "kw-relevancy"
                            : ""
                        }
                      >
                        {column === "searchVolume"
                          ? formatNumberWithCommas(params.value)
                          : column === objectAsin && params.value === 999
                          ? ""
                          : params.value}
                      </Typography>
                    </Grid>
                  );
                },
              });
            }
            return columns;
          });

          const selectionModel: number[] = [];

          const rows = data.competitorsReport.map((row: any, index: any) => ({
            id: index,
            ...row,
            [objectAsin]: row[objectAsin] ? row[objectAsin] : 999,
          }));

          if (rows.length > 0) {
            rows.forEach((item: any, index: number) => {
              if (data.selectedHelium10Keywords.length > 0) {
                data.selectedHelium10Keywords.forEach((keyword) => {
                  if (keyword.phrase === item.phrase) {
                    selectionModel.push(index);
                  }
                });
              }
            });
          }
          const selectedRows =
            data?.selectedHelium10Keywords.length > 0 ? data?.selectedHelium10Keywords : selectedKeywords || [];
          const selectedRowsWithId = findKeywordsInRows({ rows, keywords: selectedRows });
          dispatchSelectRows(selectedRowsWithId);
          setAllKeywordRows(rows);
          setState({
            ...state,
            loading: false,
            columns,
            rows,
            selectionModel,
            selectedRows: selectedRowsWithId,
          });
        } else {
          toast.error(ERR_KEYWORDS_NOT_FOUND);
          setState({
            ...state,
            loading: false,
          });
        }
      } catch (e: any) {
        if (isBadGateway(e) && retryApi < ApiRetries) {
          setRetryApi(retryApi + 1);
        } else if (isBadGateway(e)) {
          setRetryApi(retryApi + 1);
          setState({
            ...state,
            loading: false,
          });
        } else {
          setState({
            ...state,
            loading: false,
            openErrorBox: true,
            errorMessage: e,
          });
        }
      }
    } else {
      navigatePage(URL_PRODUCTS, navigate, { replace: true });
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (selectedKeywords.length > 0) {
      setState({
        ...state,
        selectedRows: selectedKeywords,
        loading: true,
      });
    } else {
      setState({ ...state, loading: true });
    }

    if (currentUser?._id) fetchKeyword();
  }, [refetchKeywords]);

  const closeDialogBox = () => {
    onClose();
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, { replace: true });
  };

  const asin = params[ParamsEnum.ASIN];

  const generateKeywordsAction = (next: boolean = false): boolean | void => {
    setState({ ...state, loading: true });
    const isArrayEquals = deepCompareArrays(selectedKeywords, state.selectedRows);
    if (isArrayEquals && next) {
      navigatePage(`${URL_PRODUCT_FETCHED}/${domain}/${asin}${PRIORITISE_KEYWORDS}`, navigate);
      return false;
    }
    dispatch(setAsinKeywordHistoryAction({ productASIN: params[ParamsEnum.ASIN] }));

    if (!isArrayEquals) {
      updateSelectedKeywordsAgainstProduct({
        productASIN: params[ParamsEnum.ASIN] as string,
        selectedHelium10Keywords: state.selectedRows.map((obj) => obj?.phrase || ""),
        userID: changeKeywordOwnerId,
      })
        .then((res: any) => {
          setState({ ...state, loading: false });

          dispatch(resetSelectedPrioritizeKeywordsDispatch());
          if (next) {
            dispatch(setSelectedKeywordsDispatch(res.data.data.selectedHelium10Keywords));
            navigatePage(`${URL_PRODUCT_FETCHED}/${domain}/${asin}${PRIORITISE_KEYWORDS}`, navigate);
          } else {
            onOpen();
            generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription({
              productASIN: params[ParamsEnum.ASIN] || "",
              titleKeywords: next ? state.selectedRows.map((keyword: any) => createKeywordObject(keyword)) : [],
              otherKeywords: [],
              searchTermKeywords: state.selectedRows.map((keyword: any) => getPhrase(keyword)),
              userID: changeKeywordOwnerId,
            });
          }
        })
        .catch((e) => {
          setState({ ...state, loading: false });
          onClose();
          toast.error(errorHandler(e));
        });
    } else if (isArrayEquals && !next) {
      onOpen();
      generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription({
        productASIN: params[ParamsEnum.ASIN] || "",
        titleKeywords: next ? state.selectedRows.map((keyword: any) => createKeywordObject(keyword)) : [],
        otherKeywords: [],
        searchTermKeywords: state.selectedRows.map((keyword: any) => getPhrase(keyword)),
        userID: changeKeywordOwnerId,
      });
    }
  };

  const showResults = async (next: boolean = false): Promise<boolean | void> => {
    const minKeywords = minKeywordsRequired;
    if (state.selectedRows.length < minKeywords) {
      toast.warning(`At least ${minKeywords} keywords are required to generate appropriate content.`);
      return false;
    }
    try {
      setState({ ...state, loading: true });
      if (userRole === RolesEnum.USER) {
        const limit = await generateProductApis.checkRegenerationLimit();
        if (limit === true) {
          generateKeywordsAction(next);
        } else {
          throw Error(limit);
        }
      } else {
        generateKeywordsAction(next);
      }
    } catch (e: any) {
      toast.error(e?.message as string);
      setState({ ...state, keywordGenerationLimit: false, loading: false });
    }
  };

  const onSelectedRows = (e: GridSelectionModel) => {
    const selectedIDs = new Set(e);
    const selectedRows = allKeywordRows.filter((row: any) => selectedIDs.has(row.id));
    setState({ ...state, selectedRows, selectionModel: e });
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let filteredValue = e.target.value;
    setSearch(filteredValue);
  };

  const handleSearchFilter = () => {
    const filteredData = allKeywordRows?.filter((item: any) => item.phrase.includes(search));
    if (search !== "") {
      setState({ ...state, rows: filteredData });
    } else {
      setState({ ...state, rows: allKeywordRows });
    }
  };

  const error: any = state.errorMessage;
  let errorMessage = "";

  if (isAxiosError(error)) {
    errorMessage = errorHandler(error);
  } else if (typeof error === "object") {
    errorMessage = error?.message || "";
  } else {
    errorMessage = error;
  }

  const handleBack = () => {
    navigate(`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${params?.asin}/${changeKeywordOwnerId}`, {
      state: { isChildComponent: location.state?.originPage },
    });
  };

  const handleShowResults = async () => {
    if (changeKeywordOwnerId) {
      // const isLimitReached = await checkIsProductOwnerReachedHisLimit(changeKeywordOwnerId);
      const isLimitReached = currentUser?.companyID
        ? await checkIsCompanyReachedHeliumOrContentRegenLimit(currentUser?.companyID)
        : await checkIsProductOwnerReachedHisLimit(changeKeywordOwnerId);

      if (isLimitReached) {
        if (currentUser?.companyID) {
          if (currentUser?.role === RolesEnum.COMPANY_ADMIN) {
            toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
          } else {
            toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
          }
        } else {
          if (changeKeywordOwnerId === currentUser?._id) {
            toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
            // onOpen();
          } else if (changeKeywordOwnerId !== currentUser?._id) {
            toast.error(UserReachedRegenLimit);
          } else {
          }
        }
        setTimeout(() => {
          handleBack();
        }, 2000);
      } else {
        showResults(false);
      }
    } else {
      showResults(false);
    }
  };

  return (
    <Grid container style={{ height: "100vh", background: "#F5F5F5" }} className="keywords-container">
      {isDesktop ? (
        <AppBar centerElement={<CenterElement page={PagesEnum.KEYWORDS} />} rightElement={<CrossElement />} />
      ) : (
        <FetchProductHeader donut={Donut} next={"Arrange Keywords"} title={"Keywords"} tabNumber={3} />
      )}
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "#F5F5F5",
          height: "100%",
          marginTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <div className={"d-flex flex-column gap-3 align-items-center mt-2"}>
          {changeKeywordOwnerId === null &&
            scrapeProduct?.product &&
            scrapeProduct?.fetchType !== ProductFetchType.MANUAL && (
              <div className={"d-flex justify-content-center my-5 w-100"}>
                <FetchProductDetails />
              </div>
            )}

          <MTypography variant={"h4"}>{"Recommended Keywords"}</MTypography>
          <div
            className={`${isDoAndDont ? classes.SelectTextContainerActive : classes.SelectTextContainer} `}
            onClick={toggleDoAndDont}
          >
            <MTypography customClass={isDoAndDont ? classes.CaptionActive : classes.Caption} variant={"subtitle2"}>
              {`Select keywords to be included in your new product content.`}
            </MTypography>
            {isDoAndDont ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
          {isDoAndDont && (
            <div className={`${isDesktop ? "" : "m-2 mx-3"}`}>
              {doAndDontKeywordPage.map((item) => (
                <p key={item.title} className={classes.DoAndDontText}>
                  {item.do ? "✅" : "❌"}
                  <span className={item.do ? classes.DoText : classes.DontText}>{item.do ? "DO" : "DO NOT"}</span>{" "}
                  {item.title}
                </p>
              ))}
            </div>
          )}
        </div>

        <>
          <Box
            sx={{
              m: 2,
              mx: { xs: 3, lg: 10 },
            }}
          >
            <MTextField
              icon={"search"}
              position={"start"}
              onChange={handleChange}
              name={"customKeyword"}
              placeholder={"Search"}
              value={search}
              margin={"dense"}
              rootClass={isMobile ? classes.SearchBarMobile : classes.SearchBarDesktop}
            />
          </Box>

          {!state.loading && !isDesktop && (
            <div className={"px-4 mt-4"}>
              {state.rows.length ? (
                state.rows.map((row: any, index: number) => (
                  <div className={"mt-3"}>
                    <KeywordCardM
                      onClick={(e: number) => onSelectedRows(generateSelectionModal(state.selectionModel, e))}
                      isActive={isCardActive(state.selectionModel, index)}
                      row={{ ...row, currentAsin, indexing: params.value }}
                      index={index}
                      key={row.id}
                    />
                  </div>
                ))
              ) : (
                <NoResultsFound />
              )}
            </div>
          )}
          {isDesktop && !state.loading ? (
            <Box
              sx={{
                overflow: "auto",
                background: "white",
                m: 5,
                mx: 10,
                borderRadius: "10px",
              }}
            >
              <Grid>
                <DataTable
                  ref={tableRef}
                  columns={state.columns}
                  pageSize={100}
                  rows={(state.rows as any) ?? []}
                  isRowSelectable={(params: GridRowParams) => !params.row?.blacklistedWord}
                  withoutPagination={state.rows.length > 100 ? false : true}
                  checkboxSelection
                  selectionModel={state.selectionModel}
                  selectedRows={(state.selectedRows as any) ?? []}
                  keepNonExistentRowsSelected
                  onSelectionModelChange={onSelectedRows}
                ></DataTable>
              </Grid>
            </Box>
          ) : isDesktop ? (
            <KeywordSkeleton />
          ) : (
            state.loading && (
              <div className={"px-4 mt-4"}>
                <KeywordsCardSkeletonM />
              </div>
            )
          )}

          {isDesktop ? (
            <div className={"d-flex justify-content-center"}>
              <div className={"d-flex justify-content-end gap-3 pb-4"} style={{ width: "90%" }}>
                <MButton onClick={() => showResults(true)} width={"default"} disabled={state.loading}>
                  Arrange Keywords
                </MButton>
                <MButton onClick={handleShowResults} variant={"text"} width={"default"} disabled={state.loading}>
                  Skip Arranging Keywords
                </MButton>
              </div>
            </div>
          ) : (
            <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, height: "100px" }} elevation={3}>
              <BottomNavigation
                showLabels
                sx={{
                  position: "absolute",
                  top: "25%",
                  left: "4%",
                  width: "92%",
                }}
              >
                <MButton
                  onClick={() => showResults(true)}
                  disabled={state.loading}
                  width={"large"}
                  size={"small"}
                  variant={"contained"}
                  rootClass={"comp-button-mb"}
                >
                  Arrange Keywords
                </MButton>
                <MButton
                  // onClick={() => showResults(false)}
                  onClick={handleShowResults}
                  disabled={state.loading}
                  width={"large"}
                  size={"small"}
                  variant={"text"}
                  rootClass={"comp-button-mb"}
                >
                  Skip Arranging Keywords
                </MButton>
              </BottomNavigation>
            </Paper>
          )}

          <KeywordsPageDialoge open={isOpen} onClose={closeDialogBox} />
        </>
      </Grid>

      <ActionBox
        open={Boolean(retryApi)}
        title={(retryApi || 0) > ApiRetries ? "Please Try Again Later" : "Loading"}
        message={
          (retryApi || 0) > ApiRetries
            ? "We're currently experiencing higher-than-normal activity. Please try your request again later. Thank you for your patience and understanding."
            : "Please wait a moment while we process your request. Thank you for your patience."
        }
        actionText={(retryApi || 0) > ApiRetries ? "Back" : ""}
        isDanger={(retryApi || 0) > ApiRetries}
        handleAction={() => {
          navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, {
            replace: true,
          });
        }}
      />

      <ActionBox
        open={state.openErrorBox}
        title={"Backend Response "}
        handleAction={() => {
          setRefetchKeywords(refetchKeywords + 1);
          setState({ ...state, openErrorBox: false });
        }}
        handleBack={() => {
          setState({ ...state, openErrorBox: false });
          navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, {
            replace: true,
          });
        }}
        actionText={errorMessage && errorMessage?.includes("We hit a snag fetching the keywords") ? "Try Again" : ""}
        message={errorMessage}
        backText={"Ok"}
      />
    </Grid>
  );
};

export default KeywordsPage;
