import React, { FC } from "react";
import MTypography from "../../../../Atoms/MTypography";
import classes from "./index.module.css";
import ProductEventBadge from "../ProductEventBadge";
import { camelCaseToPascalCaseWithApostrophe, camelCaseToPascalCaseWithSpaces } from "../../../../../utils/helpers/strings";
import { getDateWithTime } from "../../../../../utils/helpers/date";
import { EventsEnum } from "../../../../../utils/constants/enums";

interface EventCardContainerProps {
  event: any;
  isActive?: boolean;
}

const EventCardContainer: FC<EventCardContainerProps> = (props) => {
  const { event, isActive = false } = props;
  return (
    <div className={`${classes.EventCardContainer} ${isActive && classes.ActiveContainer}`}>
      <div>
        <MTypography variant="body1" customClass={classes.EventTitle}>
          {event.event === EventsEnum.AMAZON_CHOICE ? camelCaseToPascalCaseWithApostrophe(event.event || "") : camelCaseToPascalCaseWithSpaces(event.event || "")}
        </MTypography>
        <MTypography variant="body1" customClass={classes.EventDescription}>
          {event.text}
        </MTypography>
      </div>
      <div className="d-flex align-items-center flex-row gap-2 mt-2 justify-content-between">
        <ProductEventBadge badgeTitle={event.tag || ""} />
        <MTypography variant={"subtitle2"} customClass={classes.EventDate}>{`${getDateWithTime(
          event?.date?.toString(),
          true
        )}`}</MTypography>
      </div>
    </div>
  );
};

export default EventCardContainer;
