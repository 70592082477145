import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormControl, Select, SelectChangeEvent, Skeleton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

import MDatePicker from "../../../Atoms/MDatePicker";
import BsrScatterChart from "./BsrScatterChart";
import MTypography from "../../../Atoms/MTypography";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { parseJson, stringifyJson } from "../../../../utils/helpers/general-helpers";
import { BSRChartDropdownObject } from "../../../../apis/types/generate-product";
import { generateProductApis } from "../../../../apis/generate-product";

import EmptyBSRSvg from "../../../../assets/svgs/empty-bsr-chart.svg";
import MarkerSvg from "../../../../assets/svgs/marker.svg";

import classes from "./index.module.css";
import { BSRChartDropdownSelector } from "../../../../store/product/productDetails/productDetails.selectors";
import BsrPerformanceOverTime from "./BsrPerformanceOverTime";
import BsrOverAverage from "./BsrOverAverage";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import infoIcon from "../../../../assets/svgs/info.svg";
import {
  bsrChartType,
  bsrPerformanceInitialValue,
  checkBoxesForBsrAverage,
  generateBsrAverageData,
  generateBsrAverageDataForTooltip,
  generateSequentialNumbers,
  generateSortedRandomNumbers,
  getBsPerformanceAnnotations,
  removeZeroArray,
} from "./config";
import moment from "moment";
import { ContentStatusEnum } from "../../../../utils/constants/enums";
import RangeFiltersBsrAverage from "./BsrOverAverage/RangeFilters/RangeFilters";
import CheckboxFiltersBsrAverage from "./BsrOverAverage/CheckboxFilters/CheckboxFilters";
import ReactBigCalendar from "../ReactBigCalendar/ReactBigCalendar";
import TagLabel from "../../../Atoms/TagLabel";
// import TagLabel from "../../../Atoms/TagLabel";

interface ProductDetailBsrProps {
  product: any;
}
const ProductDetailBsr: React.FC<ProductDetailBsrProps> = ({ product }) => {
  const bsrChartDropdown = useSelector(BSRChartDropdownSelector);
  const [bsrSelect, setBsrSelect] = useState<string>("");
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [loadingPerformanceChart, setLoadingPerformanceChart] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(moment(new Date("2024-01-01")));
  const [endDate, setEndDate] = useState<any>(moment(new Date()));

  const [performanceChartData, setPerformanceChartData] = useState<any>(bsrPerformanceInitialValue);
  const [performanceChartAnnotation, setPerformanceChartAnnotation] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);

  const [averageCheckBox, setAverageCheckBox] = useState([]);
  const [bsrAverage, setBsrAverage] = useState<any>(null);
  const [bsrAverageData, setBsrAverageData] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedChart, setSelectedChart] = useState<string>(bsrChartType.searchVolume);
  const [firstRenderForChart, setFirstRenderForChart] = useState<boolean>(false);
  const [selectedPill, setSelectedPill] = useState<string>("all");

  const [performanceDates, setPerformanceDates] = useState<any>({
    startDate,
    endDate,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type: string) => {
    setAnchorEl(null);
    setSelectedChart(type);
  };

  const { isDesktop } = useDeviceType();
  const colors: string[] = [
    "rgba(230, 0, 76, 1)",
    "rgba(230, 0, 76, 1)",
    "rgb(119, 93, 208)",
    "rgb(0, 143, 251)",
    "rgb(0, 227, 150)",
    "rgb(254, 176, 25)",
    "rgb(251, 102, 59)",
    "#F8961E",
    "#90BE6D",
    "#43AA8B",
    "rgba(209, 12, 232, 0.85)",
  ];

  const fetchChart = (product: BSRChartDropdownObject): any => {
    setLoadingChart(true);
    generateProductApis
      .getBsrChartData(product)
      .then((res) => {
        setChartData(res);
        setLoadingChart(false);
      })
      .catch(() => {
        setLoadingChart(false);
      });
  };
  const fetchPerformanceChart = (product: BSRChartDropdownObject): any => {
    if (selectedChart !== bsrChartType.performance) {
      return false;
    }
    setLoadingPerformanceChart(true);
    generateProductApis
      .getBsrPerformanceChartData({
        productAsin: product.productAsin,
        category: product.category,
        startDate: performanceDates.startDate,
        endDate: performanceDates.endDate,
        userID: product.userID,
      })
      .then((res: any) => {
        const data = res.bsrData;
        if (data.length > 0) {
          const xAxis: any = [];
          const yAxis: any = [];
          const line: any = [];
          data.forEach((item: any) => {
            yAxis.push(item.rank || 0);
            xAxis.push(item.runDate);
            line.push(0);
          });
          setPerformanceChartData({
            yAxis,
            xAxis,
            line,
          });
          setPerformanceChartAnnotation(getBsPerformanceAnnotations(res.newIsLiveData || []));
        } else {
          setPerformanceChartData({ ...bsrPerformanceInitialValue });
        }
        setLoadingPerformanceChart(false);
      })
      .catch(() => {
        setLoadingPerformanceChart(false);
      });
  };
  const fetchAverageChart = (product: BSRChartDropdownObject): any => {
    if (selectedChart !== bsrChartType.bsrAverage) {
      return false;
    }
    setLoadingPerformanceChart(true);
    generateProductApis
      .getBsrAverageChartData({
        productAsin: product.productAsin,
        category: product.category,
        startDate,
        endDate,
        userID: product.userID,
      })
      .then((res: any) => {
        if (res.categories.length === 0) {
          setBsrAverage(null);
          setBsrAverageData(null);
        } else {
          const ticks = generateSortedRandomNumbers(res.series);

          setBsrAverage({
            ...res,
            series: removeZeroArray(res.series),
            yTicks: generateSequentialNumbers(1, ticks[4]),
          });
          console.log("test pick issue", res.categories, res, ticks, generateSequentialNumbers(1, ticks[4]));
        }
        setLoadingPerformanceChart(false);
      })
      .catch(() => {
        setLoadingPerformanceChart(false);
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (bsrAverage) {
      setBsrAverageData(
        generateBsrAverageDataForTooltip({
          chartData: bsrAverage.series,
          averageCheckbox: !!averageCheckBox.length,
          asin: product.productASIN,
        })
      );
    }
  }, [bsrAverage, averageCheckBox, bsrSelect]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const averageChartData: any = useMemo(() => {
    if (bsrAverage) {
      return generateBsrAverageData({
        chartData: bsrAverage.series,
        averageCheckbox: !!averageCheckBox.length,
        asin: product.productASIN,
      });
    } else {
      return [];
    }
  }, [bsrAverage, averageCheckBox, bsrSelect]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (bsrChartDropdown && !bsrSelect) {
      setBsrSelect(stringifyJson(bsrChartDropdown[0]));
    }
  }, [bsrChartDropdown]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (bsrSelect) {
      fetchChart(parseJson(bsrSelect));
      fetchPerformanceChart(parseJson(bsrSelect));
      fetchAverageChart(parseJson(bsrSelect));
    }
  }, [bsrSelect, selectedChart]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (product && product?.status && !firstRenderForChart) {
      if (
        product?.status === ContentStatusEnum.APPROVED ||
        product?.productLive?.isProductLive === "live" ||
        product?.productLive?.isProductLive === "partially_live"
      ) {
        setFirstRenderForChart(true);
        setSelectedChart(bsrChartType.performance);
      } else {
        setFirstRenderForChart(true);
        setSelectedChart(bsrChartType.searchVolume);
      }
    }
  }, [product]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (bsrSelect) {
      fetchAverageChart(parseJson(bsrSelect));
    }
  }, [startDate, endDate]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (bsrSelect) {
      fetchPerformanceChart(parseJson(bsrSelect));
    }
  }, [performanceDates]);

  const handleChange = (event: SelectChangeEvent) => {
    setBsrSelect(event.target.value);
    if (bsrChartType.searchVolume === selectedChart) {
      fetchChart(parseJson(event.target.value));
    } else {
      fetchPerformanceChart(parseJson(event.target.value));
      fetchAverageChart(parseJson(event.target.value));
    }
  };

  const onPerformanceDateChange = (dates: any) => {
    setPerformanceDates({ ...dates });
  };

  const handleDates = ({ type, value }: any) => {
    if (type === "startDate") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };
  const navigateBSRInfo = () =>
    window.open("https://www.automatoai.com/post/what-is-search-volume-exposure", "_blank")?.focus();

  const getLoadingSkeleton = () => {
    const skeletons = [];
    for (let i = 0; i < 9; i++) {
      skeletons.push(<Skeleton key={i} sx={{ minWidth: "100%", height: "40px", display: "block" }} />);
    }
    return skeletons;
  };

  let chartComponent = null;

  if (selectedChart === bsrChartType.searchVolume) {
    chartComponent = (
      <>
        {chartData.length > 0 ? (
          <BsrScatterChart chartSeries={chartData} chartColors={colors} />
        ) : (
          <div className={classes.BRSNoData}>
            <img src={EmptyBSRSvg} width={60} height={60} alt="" />
            <MTypography variant={"subtitle2"} customClass={classes.NODataText}>
              There is currently no ranking data available for the products selected
            </MTypography>
          </div>
        )}
      </>
    );
  } else if (selectedChart === bsrChartType.performance) {
    chartComponent = (
      <>
        {performanceChartData.yAxis.length > 0 ? (
          <BsrPerformanceOverTime
            chartSeries={[
              {
                name: "B0BLP2Y34S",
                type: "area",
                data: performanceChartData.yAxis || [],
              },
            ]}
            largestNumber={Math.max.apply(null, performanceChartData.yAxis)}
            smallestNumber={Math.min.apply(null, performanceChartData.yAxis)}
            annotations={performanceChartAnnotation}
            chartCategories={performanceChartData.xAxis}
            totalTickAmount={[...new Set(performanceChartData.yAxis)].length}
          />
        ) : (
          <div className={classes.BRSNoData}>
            <img src={EmptyBSRSvg} width={60} height={60} alt="" />
            <MTypography variant={"subtitle2"} customClass={classes.NODataText}>
              There is currently no ranking data available over time for the selected product. Please check back after a
              day for updated performance insights.
            </MTypography>
          </div>
        )}
      </>
    );
  } else if (selectedChart === bsrChartType.bsrAverage) {
    chartComponent = (
      <>
        {bsrAverage?.series.length > 0 ? (
          <BsrOverAverage
            chartSeries={averageChartData}
            annotations={performanceChartAnnotation}
            chartCategories={bsrAverage?.categories || []}
            totalTickAmount={10}
            chartData={bsrAverage}
            yTicks={bsrAverage?.yTicks || []}
            tooltipData={bsrAverageData}
          />
        ) : (
          <div className={classes.BRSNoData}>
            <img src={EmptyBSRSvg} width={60} height={60} alt="" />
            <MTypography variant={"subtitle2"} customClass={classes.NODataText}>
              There is currently no ranking data available over time for the selected product. Please check back after a
              day for updated performance insights.
            </MTypography>
          </div>
        )}
      </>
    );
  } else {
    chartComponent = <ReactBigCalendar />;
  }

  let filterComponent = null;

  if (selectedChart === bsrChartType.searchVolume) {
    filterComponent = (
      <>
        {chartData?.length > 0 && (
          <div className={"d-flex ms-3 align-items-center"}>
            <img src={MarkerSvg} alt={"marker-svg"} />
            <MTypography variant={"subtitle1"} customClass={classes.NORatingText}>
              No Rating
            </MTypography>
          </div>
        )}
      </>
    );
  } else if (selectedChart === bsrChartType.performance) {
    filterComponent = (
      <div className={""}>
        <RangeFiltersBsrAverage
          selectedPill={selectedPill}
          setSelectedPill={setSelectedPill}
          onDateChange={onPerformanceDateChange}
        />
      </div>
    );
  } else if (selectedChart === bsrChartType.bsrAverage) {
    filterComponent = (
      <div className={"w-50"}>
        <div className={"d-flex justify-content-end align-items-center"}>
          <MDatePicker
            disableFuture
            value={startDate}
            onChange={(value: any) => handleDates({ value, type: "startDate" })}
            label={"Start"}
          />
          <div className={"mx-1"}>
            <HorizontalRuleIcon />
          </div>
          <MDatePicker
            disableFuture
            value={endDate}
            onChange={(value: any) => handleDates({ value, type: "endDate" })}
            label={"End"}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className={"d-flex"}>
          <div
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className={classes.ChartTitleText}
          >
            {selectedChart}
            <ArrowDropDownIcon color={"primary"} />{" "}
            {selectedChart === bsrChartType.eventCalendar && <TagLabel type={"Beta"} label={"Beta"} />}
          </div>
        </div>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(selectedChart)}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => handleClose(bsrChartType.searchVolume)} value={bsrChartType.searchVolume}>
            <div className={"d-flex align-items-center"}>
              {bsrChartType.searchVolume}
              <img src={infoIcon} alt={"info"} className={"mx-2 cursor-pointer"} onClick={navigateBSRInfo} />
            </div>
          </MenuItem>
          <MenuItem onClick={() => handleClose(bsrChartType.performance)} value={bsrChartType.performance}>
            {bsrChartType.performance}
          </MenuItem>
          <MenuItem onClick={() => handleClose(bsrChartType.bsrAverage)} value={bsrChartType.bsrAverage}>
            <div className={"d-flex align-items-center"}>{bsrChartType.bsrAverage}</div>
          </MenuItem>
          <MenuItem onClick={() => handleClose(bsrChartType.eventCalendar)} value={bsrChartType.eventCalendar}>
            <div className={"d-flex align-items-center"}>
              {bsrChartType.eventCalendar}
              <TagLabel type={"Beta"} label={"Beta"} />
            </div>
          </MenuItem>
        </Menu>
      </div>
      <div className={`card ${classes.BsrCard}`}>
        {selectedChart !== bsrChartType.eventCalendar && (
          <div className={"d-flex justify-content-between"}>
            {/*this component renders the filters for the charts*/}

            <>{filterComponent}</>

            <div className={"d-flex gap-2 align-items-center"}>
              <div>
                {chartData?.length > 0 && (
                  <FormControl>
                    <Select
                      value={bsrSelect}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        height: "34px",
                        fontSize: isDesktop ? "12px" : "8px",
                        color: "#333",
                        lineHeight: isDesktop ? "normal" : "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        letterSpacing: "-0.08px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#D8D8D8",
                        },
                      }}
                    >
                      {bsrChartDropdown &&
                        bsrChartDropdown.length > 0 &&
                        bsrChartDropdown.map((item: BSRChartDropdownObject) => (
                          <MenuItem
                            value={stringifyJson(item)}
                            key={item.category}
                            classes={{ root: isDesktop ? classes.BsrMenuItem : classes.BsrMenuItemM }}
                          >
                            {item.category}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </div>
          </div>
        )}

        {selectedChart === bsrChartType.bsrAverage && (
          <div>
            <CheckboxFiltersBsrAverage
              checkBoxes={checkBoxesForBsrAverage}
              setCheckbox={(value: any) => {
                setAverageCheckBox(value);
              }}
              initialValue={averageCheckBox}
              noColor
            />
          </div>
        )}

        <>
          {loadingChart || loadingPerformanceChart ? (
            <div
              style={{ minHeight: "415px", width: "100%" }}
              className="d-flex justify-content-center align-items-center flex-column"
            >
              {getLoadingSkeleton()}
            </div>
          ) : (
            <>{chartComponent}</>
          )}
        </>
      </div>
    </div>
  );
};
export default ProductDetailBsr;
