import { KEY_LAST_TAB } from "../constants/store";
import { toast } from "react-toastify";
import { URL_LANDING_PAGE } from "../../routes/routes-path";
import { NavigateFunction, NavigateOptions } from "react-router-dom";
import { TabsEnum } from "../constants/enums";

export const copyText = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    toast.info("Copied to clipboard !", {
      icon: "📋",
    });
  });
};

export const copyAsinByRightClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, text: string) => {
  event.preventDefault();
  navigator.clipboard.writeText(text).then(() => {
    toast.info(`${text} Copied to clipboard !`, {
      icon: "📋",
    });
  });
};

export const getLastOpenTab = () => sessionStorage.getItem(KEY_LAST_TAB) || `${URL_LANDING_PAGE}/${TabsEnum.SAVED}`;

export const navigatePage = (tab: string, navigate: NavigateFunction, options?: NavigateOptions) => {
  sessionStorage.setItem(KEY_LAST_TAB, tab);
  navigate(tab, options);
};

export const getExceptionError = (error: any) => {
  return toast.error(error.response?.data?.message ? error.response.data.message : "Oops! Something went wrong");
};

export const isPropertyExistInObject = (object: any, property: any) => {
  if (property in object) return object[property];

  return false;
};

export function isObject(item: any): boolean {
  return typeof item === "object";
}

export const checkArrayNotEmpty = <T>(array: T[]): T[] | false => {
  if (Array.isArray(array) && array.length > 0) {
    return array;
  } else {
    return false;
  }
};

export const lightenHex = (hexCode: string): string => {
  // Remove the leading #
  hexCode = hexCode.replace("#", "");

  // Convert the hex color to RGB
  const r = parseInt(hexCode.substring(0, 2), 16);
  const g = parseInt(hexCode.substring(2, 4), 16);
  const b = parseInt(hexCode.substring(4, 6), 16);

  // Calculate the lighter RGB values
  const lighterR = Math.round(r + (255 - r) * 0.4);
  const lighterG = Math.round(g + (255 - g) * 0.4);
  const lighterB = Math.round(b + (255 - b) * 0.4);

  // Convert the lighter RGB values back to hex
  return `#${((lighterR << 16) | (lighterG << 8) | lighterB).toString(16).padStart(6, "0")}`;
};
export const handleOpenLink = (link: string) => {
  window.open(link, "_blank")?.focus();
};

export const formatUploadFileSize = (bytes: number) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (bytes >= megabyte) {
    return (bytes / megabyte).toFixed(2) + " MB";
  } else if (bytes >= kilobyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else {
    return bytes + " bytes";
  }
};

export const checkFileSizeLessThan10Mbs = (bytes: number) => {
  const megabyte = 1024 * 1024; // 1 MB in bytes
  const maxFileSize = 5 * megabyte; // Maximum allowed file size in bytes

  return bytes <= maxFileSize;
};

export const validUploadedFileTypes = (fileType: string): boolean => {
  const validTypes = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/png",
    "image/jpg",
    "image/jpeg",
  ];
  return validTypes.includes(fileType);
};

export const validMediaFileTypes = (fileType: string): boolean => {
  const validTypes = ["image/png", "image/jpg", "image/jpeg"];
  return validTypes.includes(fileType);
};

export function isValidAmazonASIN(asin: string) {
  // ASIN must be exactly 10 characters
  if (asin.length !== 10) {
    return false;
  }

  // ASIN should start with 'B0'
  if (asin.substring(0, 2) !== "B0") {
    return false;
  }

  // If all conditions are met, the ASIN is valid
  return true;
}

export const checkElementExistInArray = (string: string, array: string[] | []) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === string) {
      return true;
    }
  }
  return false;
};
