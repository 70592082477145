import { GetRainForestProductPayload, UnattachedProductPayload } from "../../../apis/types/product";
import { Dispatch } from "redux";
import { Competitor, ScrapeProduct } from "../../../interfaces/scrapeProduct";
import { ProductApis } from "../../../apis/product";
import { deepCompareArrays } from "../../../utils/helpers/general-helpers";
import { ContentStatusEnum, CountryDomainEnum, ProductFetchType } from "../../../utils/constants/enums";
import { ApiDelayTimeInMilliseconds, ApiRetries, isBadGateway, sleep } from "../../../utils/helpers/apis";
import { AppState } from "../../reducers";

export enum ProductScrapeActionTypes {
  setScrapeProduct = "setScrapeProduct",
  setRainForestProduct = "setRainForestProduct",
}

export interface SetScrapeProduct {
  type: ProductScrapeActionTypes.setScrapeProduct;
  payload: ScrapeProduct;
}

export const scrapeProductDispatch = (scrapeProduct: any) => ({
  type: ProductScrapeActionTypes.setScrapeProduct,
  payload: scrapeProduct,
});

export interface SetRainforestProduct {
  type: ProductScrapeActionTypes.setRainForestProduct;
  payload: ScrapeProduct;
}

export const scrapeRainforestDispatch = (scrapeProduct: ScrapeProduct): SetRainforestProduct => ({
  type: ProductScrapeActionTypes.setRainForestProduct,
  payload: scrapeProduct,
});
export const resetScrapeProduct = (dispatch: Dispatch<any>) => dispatch(scrapeProductDispatch({} as ScrapeProduct));
const updateCompetitors = async (
  product: ScrapeProduct,
  dispatch: Dispatch<any>,
  checks: { isRetry?: boolean; force?: boolean },
  getState: AppState
) => {
  const { isRetry, force = false } = checks;
  const currentUserID = getState().user?.dbUser?._id;
  const userID =
    localStorage.getItem("productOwnerId") !== undefined && localStorage.getItem("productOwnerId") !== null
      ? localStorage.getItem("productOwnerId")
      : currentUserID;

  try {
    const asin = product?.productASIN || product?.product?.asin;
    if (force) dispatch(scrapeProductDispatch({ ...product, updatingDomainLoading: true }));
    // condition added to prevent updateAllCompetitorsByProductASIN api call while product is in generating status
    if (
      (product?.productStatus !== ContentStatusEnum.GENERATING && !product?.productAlreadyExistWithCurrentUser) ||
      product?.isUnAttached
    ) {
      const response = await ProductApis.updateAllCompetitorsByProductASIN(asin, userID, force, isRetry);
      dispatch(
        scrapeProductDispatch({
          ...response,
          showCompetitors: product.showCompetitors,
          productFetch: true,
          competitorsFetch: true,
          isUnAttached: product?.isUnAttached,
          fetchType: product.fetchType,
          updatingDomainLoading: false,
          productAlreadyExistWithDifferentDomain: product?.productAlreadyExistWithDifferentDomain,
        })
      );
      return response;
    } else {
      return dispatch(
        scrapeProductDispatch({
          ...product,
          competitorsFetch: true,
        })
      );
    }
  } catch (error: any) {
    if (isBadGateway(error) || (error.hasOwnProperty("code") && error.code === "ERR_NETWORK")) {
      const retryCount = product?.retryCount || 0;
      const productUpdated = { ...product, retryCount: retryCount + 1 };
      if (retryCount < ApiRetries) {
        dispatch(scrapeProductDispatch(productUpdated));
        await sleep(ApiDelayTimeInMilliseconds);
        await updateCompetitors(productUpdated, dispatch, { isRetry: true, force }, getState);
      } else {
        dispatch(scrapeProductDispatch({ ...productUpdated, updatingDomainLoading: false }));
        throw error;
      }
    } else {
      dispatch(scrapeProductDispatch({ ...product, updatingDomainLoading: false }));
      throw error;
    }
    return product;
  }
};

export const getScrapeProductAction =
  ({ asin }: any): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      let rainForestProduct: ScrapeProduct;
      let productFetch = {} as ScrapeProduct;
      dispatch(scrapeProductDispatch(productFetch));
      rainForestProduct = await ProductApis.getRainForestProductRatings({
        asin,
      });

      if (rainForestProduct !== undefined) {
        dispatch(
          scrapeProductDispatch({
            product: rainForestProduct,
            allCompetitors: [],
            domain: CountryDomainEnum.AMAZON_USA,
            productAlreadyExistWithCurrentUser: false,
            productAlreadyExistWithDifferentDomain: false,
            productStatus: "",
            selectedCompetitors: [],
          })
        );
      }
    } catch (e) {
      console.log("getScrapeProductAction Error", e);
    }
  };

export const rainForestProductAction =
  ({ domain, asin, userID }: GetRainForestProductPayload): any =>
  async (dispatch: Dispatch<any>, getState: AppState) => {
    let productFetch = {} as ScrapeProduct;
    const userId = localStorage.getItem("productOwnerId") ? localStorage.getItem("productOwnerId") : null;
    try {
      let rainForestProduct: ScrapeProduct;

      dispatch(scrapeProductDispatch(productFetch));
      rainForestProduct = await ProductApis.getRainForestProduct({
        domain,
        asin,
        isGoGoAutomato: false,
        userID: userID ? userID : userId,
      });

      if (rainForestProduct?.product?.asin || (rainForestProduct && rainForestProduct.hasOwnProperty("newToAmazon"))) {
        dispatch(scrapeRainforestDispatch(rainForestProduct));
        dispatch(
          scrapeProductDispatch({ ...rainForestProduct, productFetch: true, fetchType: ProductFetchType.STANDARD })
        );
        productFetch = {
          ...rainForestProduct,
          product: {
            ...rainForestProduct.product,
            asin: rainForestProduct.product.asin !== asin ? asin : rainForestProduct?.product?.asin,
          },
          productFetch: true,
          showCompetitors: !Boolean(rainForestProduct?.selectedCompetitors?.length),
          fetchType: ProductFetchType.STANDARD,
        };
        if (!rainForestProduct?.productAlreadyExistWithDifferentDomain) {
          return await updateCompetitors(productFetch, dispatch, {}, getState);
        } else {
          dispatch(scrapeProductDispatch({ ...productFetch, confirmDomainUpdateBox: true }));
          return productFetch;
        }
      } else {
        dispatch(scrapeProductDispatch({ productFetch: true } as ScrapeProduct));
        throw new Error("Data Not Found");
      }
    } catch (error: any) {
      throw error;
    }
  };

export const updateProductDomainAction =
  (product: ScrapeProduct, domain: CountryDomainEnum, update: boolean): any =>
  async (dispatch: Dispatch<any>, getState: AppState) => {
    let productUpdated = { ...product };
    try {
      if (update) {
        await ProductApis.updateProductDomain({ domain, productASIN: product?.product?.asin || "" });
      }
      productUpdated = { ...product, domain, confirmDomainUpdateBox: false, showCompetitors: update };

      if (product.fetchType === ProductFetchType.STANDARD) {
        return await updateCompetitors(productUpdated, dispatch, { force: update }, getState);
      }

      dispatch(scrapeProductDispatch(productUpdated));
      return productUpdated;
    } catch (error: any) {
      throw new Error(error);
    }
  };

export const fetchGoGoProductAction =
  ({ domain, asin, companyAdminID = null, addedBy = null }: GetRainForestProductPayload): any =>
  async (dispatch: Dispatch<any>, getState: any) => {
    try {
      const currentUser = getState().user.dbUser;
      dispatch(scrapeProductDispatch({} as ScrapeProduct));

      let payload: any = {
        productASINS: [asin],
        domain,
        userID: currentUser?._id,
      };

      if (companyAdminID && addedBy) {
        payload = {
          ...payload,
          userID: companyAdminID,
          addedBy,
        };
      }

      return await ProductApis.generateMultipleGogo(payload);
    } catch (error) {
      throw error;
    }
  };

export const updateProductBrandAction =
  (brand: string, scrapeProduct: ScrapeProduct): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      await ProductApis.updateProductBrandByProductASIN({
        productASIN: scrapeProduct?.productASIN as string,
        brand,
      });

      dispatch(scrapeProductDispatch({ ...scrapeProduct, product: { ...scrapeProduct.product, brand } }));

      return {};
    } catch (error) {
      throw error;
    }
  };
export const updateProductSelectedCompetitorsAction =
  (selectedCompetitors: Competitor[], scrapeProduct: ScrapeProduct): any =>
  async (dispatch: Dispatch<any>) => {
    try {
      const oldSelected = scrapeProduct?.selectedCompetitors?.map((comp) => comp?.asin);
      const competitorsASIN = selectedCompetitors.map((comp) => comp.asin);
      const productASIN = scrapeProduct?.productASIN ? scrapeProduct?.productASIN : scrapeProduct?.product?.asin;
      await ProductApis.updateSelectedCompetitorsByProductASIN({
        productASIN: productASIN as string,
        competitorsASIN,
        userID: localStorage.getItem("productOwnerId") ? localStorage.getItem("productOwnerId") : null,
      });
      dispatch(
        scrapeProductDispatch({
          ...scrapeProduct,
          selectedCompetitors,
          competitorsUpdated: !deepCompareArrays(oldSelected, competitorsASIN),
        })
      );
      return scrapeProduct;
    } catch (e) {
      throw e;
    }
  };
export const unAttachedProductAction =
  ({ product, runType }: UnattachedProductPayload): any =>
  async (dispatch: Dispatch<any>, getState: AppState) => {
    try {
      dispatch(scrapeProductDispatch({} as ScrapeProduct));
      const unAttachedProduct = await ProductApis.generateUnattachedProduct({ product });
      if (unAttachedProduct?.productASIN) {
        if (runType === ProductFetchType.MANUAL) {
          dispatch(scrapeProductDispatch({ ...unAttachedProduct, productFetch: true, isUnAttached: true }));
          return await updateCompetitors(
            {
              ...unAttachedProduct,
              showCompetitors: !Boolean(unAttachedProduct?.selectedCompetitors?.length),
              isUnAttached: true,
              fetchType: ProductFetchType.MANUAL,
            },
            dispatch,
            {},
            getState
          );
        } else {
          return unAttachedProduct;
        }
      }
      return {};
    } catch (error) {
      throw error;
    }
  };

export type ProductsScrapeReducerActionType = SetScrapeProduct | SetRainforestProduct;
