import { camelCaseToPascalCaseWithApostrophe, camelCaseToPascalCaseWithSpaces } from "../../../../utils/helpers/strings";

import BuyBox from "../../../../assets/svgs/events/buyBox.svg";
import OutOfStock from "../../../../assets/svgs/events/out-of-stock.svg";
import { ArrowUpward, ArrowDownward, StarsOutlined } from "@mui/icons-material";
import { EventsEnum } from "../../../../utils/constants/enums";

const redColor = "#FF4C05";
const greenColor = "#0CC683";
export const configureEventsResponse = (events: any, isMobile: boolean): any => {
  const transformEvents: any = [];

  if (events.length > 0) {
    events.forEach((event: any) => {
      const year = event.date.split("-")[0];
      const month = event.date.split("-")[1];
      const day = event.date.split("-")[2].split("T")[0];
      const date = new Date(year, month - 1, day);

      const eventName = event.event.includes("competitorPrice") ? "competitorPrice" : event.event;

      switch (eventName) {
        case "price":
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex align-items-center" : "d-flex align-items-center gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} `}</div>
                {event.status === "increased" ? (
                  <ArrowUpward style={{ width: "13px", height: "13px" }} htmlColor={redColor} />
                ) : (
                  <ArrowDownward style={{ width: "13px", height: "13px" }} width={20} htmlColor={greenColor} />
                )}
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case EventsEnum.RATING:
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex align-items-center" : "d-flex align-items-center gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} ${event.count}`}</div>
                {event.status ? (
                  <ArrowUpward style={{ width: "13px", height: "13px" }} htmlColor={greenColor} />
                ) : (
                  <ArrowDownward style={{ width: "13px", height: "13px" }} htmlColor={redColor} />
                )}
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case EventsEnum.COMPETITORS_PRICE:
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex align-items-center" : "d-flex align-items-center gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} ${event?.asin || ""}`}</div>
                {event.status === "increased" ? (
                  <ArrowUpward style={{ width: "13px", height: "13px" }} htmlColor={greenColor} />
                ) : (
                  <ArrowDownward style={{ width: "13px", height: "13px" }} htmlColor={redColor} />
                )}
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;

        case EventsEnum.STANDARD_DELIVERY_DAYS:
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex align-items-center" : "d-flex align-items-center gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} ${event?.asin || ""}`}</div>
                {event.status === "increased" ? (
                  <ArrowUpward style={{ width: "13px", height: "13px" }} htmlColor={redColor} />
                ) : (
                  <ArrowDownward style={{ width: "13px", height: "13px" }} htmlColor={greenColor} />
                )}
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case EventsEnum.FASTEST_DELIVERY_DAYS:
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex align-items-center" : "d-flex align-items-center gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} ${event?.asin || ""}`}</div>
                {event.hasOwnProperty("status") && event.status === "increased" ? (
                  <ArrowUpward style={{ width: "13px", height: "13px" }} htmlColor={redColor} />
                ) : (
                  <ArrowDownward style={{ width: "13px", height: "13px" }} htmlColor={greenColor} />
                )}
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: event.hasOwnProperty("exist") ? (event?.exist ? "#0CC683" : "#FF4C05") : "#E6B540",
          });
          break;
        case "buyBox":
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex " : "d-flex gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)}`}</div>
                <img src={BuyBox} width={14} alt="" />
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: event.exist ? "#0CC683" : "#FF4C05",
          });
          break;

        case "negativeReviews":
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex align-items-center" : "d-flex align-items-center gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} ${event.count} `}</div>
                {event.text.includes("increase") ? (
                  <ArrowUpward style={{ width: "13px", height: "13px" }} htmlColor={redColor} />
                ) : (
                  <ArrowDownward style={{ width: "13px", height: "13px" }} width={20} htmlColor={greenColor} />
                )}
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case "negativeReviewsOnPage":
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex align-items-center" : "d-flex align-items-center gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} ${event.count} `}</div>
                <StarsOutlined style={{ width: "15px" }} width={20} htmlColor={event?.status ? redColor : greenColor} />
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case "variants":
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex" : "d-flex gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)} ${event.activeVariantAsin} `}</div>
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: "#E6B540",
          });
          break;
        case "inventory":
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex" : "d-flex gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${camelCaseToPascalCaseWithSpaces(event.event)}`}</div>
                {event.text.includes("Out Of Stock") && <img src={OutOfStock} width={14} alt="" />}
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: event.exist ? "#0CC683" : "#FF4C05",
          });
          break;
        default:
          transformEvents.push({
            ...event,
            title: (
              <div className={isMobile ? "d-flex " : "d-flex gap-2"}>
                <div
                  className={isMobile ? "event-text-div-mobile" : "event-text-div text-ellipsis"}
                >{`${event.event === EventsEnum.AMAZON_CHOICE ? camelCaseToPascalCaseWithApostrophe(event.event || "") : camelCaseToPascalCaseWithSpaces(event.event)}`}</div>
              </div>
            ),
            desc: event.text,
            start: date,
            end: date,
            color: event.exist ? "#0CC683" : "#FF4C05",
          });
          break;
      }
    });
  }

  return transformEvents;
};

// For price change, negative reviews, delivery date = UP = increase = RED, and DOWN = decrease = GREEN
