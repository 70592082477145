import { ContentStatusEnum, RolesEnum } from "../constants/enums";

const internalDomain = "automatoai.com";
export const showInfinityIconForHelium10OnUserManagement = (role: RolesEnum) =>
  userRole.isAdminOrEditorOrInternalOrOwner(role);
export const showInfinityIconForContentGenerationOnUserManagement = (role: RolesEnum) =>
  userRole.isAdminOrEditorOrInternalOrOwner(role);
export const showInfinityIconForContentRegenerationUserManagement = (role: RolesEnum) =>
  userRole.isAdminOrEditorOrInternalOrOwner(role);
export const showActionIconOnUserManagement = (role: RolesEnum, email: string, currentUserEmail: string) =>
  !userRole.isOwner(role) && email !== currentUserEmail;
export const showRerunButton = (role: RolesEnum, _status: ContentStatusEnum) => {
  if (_status === ContentStatusEnum.GENERATING) {
    return false;
  } else if (_status === ContentStatusEnum.ERROR) {
    return true;
  } else {
    return userRole.isAdminOrInternalOrOwner(role) || userRole.isPaidUser(role) || userRole.isPaidEditor(role);
  }
};
export const productActionsMenuOnSavedProduct = (productStatus: ContentStatusEnum, role: RolesEnum) => {
  if (
    (productStatus === ContentStatusEnum.GENERATING || userRole.isUser(role)) &&
    productStatus !== ContentStatusEnum.ERROR
  ) {
    return false;
  } else {
    return true;
  }
};
export const productActionsMenuOnSavedProductForCompany = (
  productStatus: ContentStatusEnum,
  companyRole: RolesEnum
) => {
  if (
    (productStatus === ContentStatusEnum.GENERATING || companyRoles.isTrailCompany(companyRole)) &&
    productStatus !== ContentStatusEnum.ERROR
  ) {
    return false;
  } else {
    return true;
  }
};

export const showUserEmailInProductsPage = (role: RolesEnum) => {
  return userRole.isAdminOrEditorOrInternalOrOwner(role) || userRole.isCompanyAdmin(role);
};

export const showDeleteIconOnWaitingGrid = (
  role: RolesEnum,
  userEmail: string,
  currentUserEmail: string,
  companyRole?: RolesEnum
) => {
  if (userRole.isAdminOrEditorOrOwner(role)) return true;
  // else if (userRole.isInternalOrPaidOrPaidEditor(role) && userEmail === currentUserEmail) return true;
  else if (userRole.isInternal(role) && userEmail === currentUserEmail) return true;
  else if (userRole.isPaid(role) || userRole.isPaidEditor(role)) return true;
  else if (userRole.isCompanyAdmin(role)) {
    if (companyRole) {
      return companyRoles.isPaidCompany(companyRole);
    }
    return false;
  }
  return false;
};

export const showDeleteIconOnProductPage = (
  role: RolesEnum,
  userEmail: string,
  currentUserEmail: string
  // companyRole?: RolesEnum
) => {
  // if (userRole.isAdminOrEditorOrOwner(role)) return true;
  // else if (userRole.isInternalOrPaidOrPaidEditor(role) && userEmail === currentUserEmail) return true;
  // else if (userRole.isCompanyAdmin(role)) {
  //   if (companyRole) {
  //     return companyRoles.isPaidCompany(companyRole);
  //   }
  //   return false;
  // }
  // return false;
  if (userRole.isInternal(role) && userEmail === currentUserEmail) return true;
  else if (userRole.isAdminOrOwner(role) || userRole.isEditor(role)) return true;
  else return false;
};

export const showDeleteIconOnWaitingGridCompany = (companyRole: RolesEnum, companyUserRole: RolesEnum) => {
  if (companyRoles.isPaidCompany(companyRole) && userRole.isCompanyAdmin(companyUserRole)) return true;
  return false;
};

export const showDeleteIconOnProductPageCompany = (companyRole: RolesEnum, companyUserRole: RolesEnum) => {
  // returning false in every case for ticket AM-2187
  if (companyRoles.isPaidCompany(companyRole) && userRole.isCompanyAdmin(companyUserRole)) return false;
  return false;
};

export const showDeleteAndEditIconOnWaitingGrid = (role: RolesEnum) => {
  // paid paidEditor user
  if (userRole.isPaidOrPaidEditorOrUser(role)) return true;
  else if (userRole.isAdminOrInternalOrOwner(role)) return true;
  return false;
};
export const showLiveCheckButton = (_role: RolesEnum, status: ContentStatusEnum) =>
  status !== ContentStatusEnum.ERROR && status !== ContentStatusEnum.GENERATING;
// same method is using on WaitingGrid MultiDelete action
export const showMultipleProductDeleteButton = (role: RolesEnum) => {
  return userRole.isAdminOrEditorOrOwner(role);
};
export const showMultipleProductDeleteButtonCompany = (role: RolesEnum, compRole: RolesEnum) => {
  // returning false in every case for ticket AM-2187
  if (userRole.isCompanyAdmin(role) && companyRoles.isPaidCompany(compRole)) return false;
  return false;
};
export const showAddTagOption = (role: RolesEnum) => userRole.isAll(role) && false;
export const showAccountManagementTab = (role: RolesEnum) => userRole.isAdminOrOwner(role);

export const showWaitingGridOnClientsForCompany = (role: RolesEnum) => {
  return userRole.isAdminOrOwner(role);
};

export const canUpdateContentGeneration = (role: RolesEnum) => userRole.isPaidOrPaidEditorOrUser(role);
export const canUpdateHelium10Calls = (role: RolesEnum) => userRole.isPaidOrPaidEditorOrUser(role);
export const canUpdateContentRegeneration = (role: RolesEnum) => userRole.isPaidOrPaidEditorOrUser(role);
export const showApprovedButtonOnDetailPage = (role: RolesEnum, status: ContentStatusEnum) =>
  status !== ContentStatusEnum.ERROR &&
  status !== ContentStatusEnum.APPROVED &&
  ((userRole.isEditor(role) && status !== ContentStatusEnum.EDITED) ||
    userRole.isAdminOrEditorOrInternalOrOwnerOrPaidEditor(role) ||
    userRole.isPaid(role));

export const showUnApprovedButtonOnDetailPage = (role: RolesEnum, status: ContentStatusEnum) =>
  status !== ContentStatusEnum.ERROR &&
  (status === ContentStatusEnum.APPROVED || status === ContentStatusEnum.PUBLISHED) &&
  // @ts-ignore
  ((userRole.isEditor(role) && status !== ContentStatusEnum.EDITED) ||
    userRole.isAdminOrEditorOrInternalOrOwnerOrPaidEditor(role) ||
    userRole.isPaid(role));

export const showPublishOnAmazonButton = (
  role: RolesEnum,
  status: ContentStatusEnum,
  isSellerPartnerAuthorized: boolean,
  email: string
) =>
  status === ContentStatusEnum.APPROVED &&
  isSellerPartnerAuthorized &&
  (userRole.isPaid(role) ||
    userRole.isPaidEditor(role) ||
    userRole.isDomainUser(email)); /* && process.env.REACT_APP_ENV === "development"*/
export const showPublishOnAmazonButtonCompany = (
  role: RolesEnum,
  status: ContentStatusEnum,
  isSellerPartnerAuthorized: boolean
) =>
  status === ContentStatusEnum.APPROVED &&
  isSellerPartnerAuthorized &&
  (userRole.isUser(role) || userRole.isCompanyAdmin(role)); /* && process.env.REACT_APP_ENV === "development"*/

export const showApprovedButtonOnDetailPageCompany = (
  status: ContentStatusEnum,
  role: RolesEnum,
  companyRole: RolesEnum
) =>
  status !== ContentStatusEnum.ERROR &&
  status !== ContentStatusEnum.APPROVED &&
  (userRole.isUser(role) || userRole.isCompanyAdmin(role)) &&
  companyRoles.isPaidCompany(companyRole);

export const showUnApprovedButtonOnDetailPageCompany = (
  status: ContentStatusEnum,
  role: RolesEnum,
  companyRole: RolesEnum
) =>
  status !== ContentStatusEnum.ERROR &&
  (status === ContentStatusEnum.APPROVED || status === ContentStatusEnum.PUBLISHED) &&
  (userRole.isUser(role) || userRole.isCompanyAdmin(role)) &&
  companyRoles.isPaidCompany(companyRole);

// role !== RolesEnum.USER;
export const enableEditFunctionalityOnDetailPage = (role: RolesEnum, status: ContentStatusEnum) =>
  userRole.isAdminOrEditorOrOwnerOrPaidEditor(role) && status !== ContentStatusEnum.APPROVED;

export const enableEditFunctionalityOnDetailPageCompany = (role: RolesEnum, status: ContentStatusEnum) =>
  companyRoles.isTrailCompany(role) && status !== ContentStatusEnum.APPROVED;

export const showTriggerHistroyButtonOnDetailPage = (role: RolesEnum, status: ContentStatusEnum) =>
  userRole.isAdminOrOwner(role) && status === ContentStatusEnum.APPROVED;

export const showGoGoButton = (role: RolesEnum) => userRole.isAdminOrInternalOrOwner(role);
export const showProductVisibilityFilter = (role: RolesEnum) => userRole.isAdminOrInternalOrOwner(role);
export const checkIsUser = (role: RolesEnum) => userRole.isUser(role);

export const isExportButton = (role: RolesEnum, companyId = "", companyRole: any = "") => {
  if (companyId) {
    return !companyRoles.isPaidCompany(companyRole);
  } else {
    return userRole.isUser(role);
  }
};
export const checkIsTrailCompany = (role: RolesEnum) => companyRoles.isTrailCompany(role);
export const ShowChangeKeywordsButton = (role: RolesEnum, companyID: string = "") => {
  return userRole.isAdminOrInternalOrOwner(role) || companyID || userRole.isEditor(role);
};
export const showUpgradeOnProductDetailPage = (role: RolesEnum) => {
  return userRole.isUser(role);
};
export const showUpgradeOnProductDetailPageCompany = (role: RolesEnum) => {
  return companyRoles.isTrailCompany(role);
};

// same method is using for showing content in table and card
// also controlling generate new key action in AutomatoApiAccess Component
export const showAutomatoApiAccess = (role: RolesEnum) => {
  return userRole.isAdminOrOwner(role);
};
export const checkIsCompanyAdmin = (role: RolesEnum) => {
  return userRole.isCompanyAdmin(role);
};

export const showUpgradeNowBannerOnLayout = (role: RolesEnum) => userRole.isPaidOrPaidEditorOrUser(role);
export const showUpgradeNowBannerOnLayoutCompany = (role: RolesEnum) => userRole.isCompanyAdmin(role);
export const showAuthorizedBarOnLayoutCompany = (role: RolesEnum, companyRole: RolesEnum) => {
  if (userRole.isCompanyAdmin(role) && companyRoles.isPaidCompany(companyRole)) {
    return true;
  } else {
    return false;
  }
};
export const showAuthorizedBarOnLayout = (role: RolesEnum, email: string) => {
  if (email) {
    if (userRole.isPaid(role) || userRole.isPaidEditor(role) || userRole.isDomainUser(email)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const showDensityOnDetailsPage = (role: RolesEnum) => userRole.isAdminOrInternalOrOwner(role);
export const showDensityOnDetailsPageCompany = (companyUserRole: RolesEnum, companyRole: RolesEnum) =>
  userRole.isCompanyAdmin(companyUserRole) && companyRoles.isPaidCompany(companyRole);

export const isDomainUserAndNewRoleIsUser = (email: string, role: RolesEnum) =>
  userRole.isDomainUser(email) && userRole.isUser(role);

export const userRole = {
  isAdminOrEditorOrInternalOrOwner(role: RolesEnum) {
    return (
      role === RolesEnum.ADMIN || role === RolesEnum.EDITOR || role === RolesEnum.OWNER || role === RolesEnum.INTERNAL
    );
  },
  isAdminOrEditorOrOwner(role: RolesEnum) {
    return role === RolesEnum.ADMIN || role === RolesEnum.EDITOR || role === RolesEnum.OWNER;
  },
  isAdminOrEditorOrOwnerOrPaidEditor(role: RolesEnum) {
    return (
      role === RolesEnum.ADMIN ||
      role === RolesEnum.EDITOR ||
      role === RolesEnum.OWNER ||
      role === RolesEnum.PAID_EDITOR
    );
  },
  isAdminOrInternalOrOwner(role: RolesEnum) {
    return role === RolesEnum.ADMIN || role === RolesEnum.INTERNAL || role === RolesEnum.OWNER;
  },
  isAdminOrInternal(role: RolesEnum) {
    return role === RolesEnum.ADMIN || role === RolesEnum.INTERNAL;
  },
  isAdminOrOwner(role: RolesEnum) {
    return role === RolesEnum.ADMIN || role === RolesEnum.OWNER;
  },
  isAdminOrOwnerOrPaidEditor(role: RolesEnum) {
    return role === RolesEnum.ADMIN || role === RolesEnum.OWNER || role === RolesEnum.PAID_EDITOR;
  },
  isPaidUser(role: RolesEnum) {
    return role === RolesEnum.PAID;
  },
  isPaidEditor(roles: RolesEnum) {
    return roles === RolesEnum.PAID_EDITOR;
  },
  isAdminOrEditorOrInternalOrOwnerOrPaidEditor(role: RolesEnum) {
    return (
      role === RolesEnum.ADMIN ||
      role === RolesEnum.EDITOR ||
      role === RolesEnum.INTERNAL ||
      role === RolesEnum.OWNER ||
      role === RolesEnum.PAID_EDITOR
    );
  },
  isAdminOrInternalOrOwnerOrPaidOrPaidEditor(role: RolesEnum) {
    return (
      role === RolesEnum.ADMIN ||
      role === RolesEnum.INTERNAL ||
      role === RolesEnum.OWNER ||
      role === RolesEnum.PAID ||
      role === RolesEnum.PAID_EDITOR
    );
  },
  isEditor(role: RolesEnum) {
    return role === RolesEnum.EDITOR;
  },
  isInternalOrPaidOrUser(role: RolesEnum) {
    return role === RolesEnum.INTERNAL || role === RolesEnum.PAID || role === RolesEnum.USER;
  },
  isInternalOrPaidOrPaidEditor(role: RolesEnum) {
    return role === RolesEnum.INTERNAL || role === RolesEnum.PAID || role === RolesEnum.PAID_EDITOR;
  },
  isOwner(role: RolesEnum) {
    return role === RolesEnum.OWNER;
  },
  isPaid(role: RolesEnum) {
    return role === RolesEnum.PAID;
  },
  isPaidOrPaidEditorOrUser(role: RolesEnum) {
    return role === RolesEnum.PAID || role === RolesEnum.PAID_EDITOR || role === RolesEnum.USER;
  },
  isUser(role: RolesEnum) {
    return role === RolesEnum.USER;
  },
  isInternal(role: RolesEnum) {
    return role === RolesEnum.INTERNAL;
  },
  isAll(role: RolesEnum) {
    return (
      role === RolesEnum.ADMIN ||
      role === RolesEnum.EDITOR ||
      role === RolesEnum.INTERNAL ||
      role === RolesEnum.OWNER ||
      role === RolesEnum.PAID ||
      role === RolesEnum.PAID_EDITOR ||
      role === RolesEnum.USER
    );
  },
  isAutomatoUser(email: string): boolean {
    return email.includes("@automatoai");
  },
  isDomainUser(email: string) {
    const emailDomain = email.split("@")[1];
    return emailDomain === internalDomain;
  },
  isCompanyAdmin(role: RolesEnum) {
    return role === RolesEnum.COMPANY_ADMIN;
  },
};

// checking company roles here
const companyRoles = {
  isTrailCompany(role: RolesEnum) {
    return role === RolesEnum.TRIAL;
  },
  isPaidCompany(role: RolesEnum) {
    return role === RolesEnum.PAID;
  },
};
