import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { BottomNavigation, CircularProgress } from "@mui/material";

import {
  ProductSearchFilterSelector,
  SavedProductsSelector,
  TotalProductSelector,
} from "../../../../store/product/product.selector";
import ProductDetailCard from "../../../Molecules/ProductDetailCard";
import { Product } from "../../../../apis/types/generate-product";
import { ContentStatusEnum, RolesEnum, TabsEnum } from "../../../../utils/constants/enums";
import MTypography from "../../../Atoms/MTypography";
import MButton from "../../../Atoms/MButton";
import { URL_FETCH_ASIN, URL_PRODUCTS } from "../../../../routes/routes-path";
import { navigatePage } from "../../../../utils/helpers/common";
import {
  getUserProductAction,
  handleSelectAllProductActionM,
  resetProductsDispatch,
  setProductSearchFilterDispatch,
  setProductSearchResetDispatch,
} from "../../../../store/product/product.actions";
import { errorHandler } from "../../../../utils/helpers/apis";
import {
  checkLiveProductLimit,
  downloadSavedProductCsv,
  downloadSelectedProductsCsv,
} from "../../../../pages/SavedProducts/config";
import classes from "./index.module.css";
import { DbUserSelector } from "../../../../store/user/user.selector";
import {
  isExportButton,
  showAddTagOption,
  showLiveCheckButton,
  showMultipleProductDeleteButton,
  showMultipleProductDeleteButtonCompany,
  showUserEmailInProductsPage,
  showWaitingGridOnClientsForCompany,
} from "../../../../utils/helpers/priviligesChecks";
import { defaultPageSize, usePagination } from "../../../../hooks/usePagination";
import DeleteIcon from "@mui/icons-material/Delete";
import { generateProductApis } from "../../../../apis/generate-product";
import ConfirmBox from "../../../mui/dialogebox/confirmBox";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import backIcon from "../../../../assets/svgs/back-icon.svg";
import MBottomSheet from "../../../Atoms/MBottomSheet";
import TagPopper from "../TagPopper";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import AsinsViewToolbarM from "./AsinsViewToolbarM";
import { debounce } from "lodash";
import { contentFilters } from "../Brands/config";
import WaitingGrid from "../WaitingGrid/WaitingGrid";
import AsinsCardAnimation from "../../../Molecules/AnimatedSkeletonCard/AsinsCardSkeletonM";
import NoResultsFound from "../../../Atoms/NoResultsFound/NoResultsFound";
import ProductStatusCounter from "../SavedProductHeader/ProductStatusCounter";
import ExportAsinsDialoge from "../../../mui/dialogebox/exportAsinsDialoge";
import { CompanyRoleSelector } from "../../../../store/company/company.selector";
import { SUCC_CSV_EXPORT } from "../../../../utils/constants/messages/success";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

interface SavedProductsState {
  loading: boolean;
  openPopper: boolean;
  anchorEl: SVGSVGElement | null;
}

interface SavedProductsMProps {
  isChildComponent?: boolean;
  isTeamManagementUser?: boolean;
  teamUserId?: string;
  isCompany?: boolean;
}
const SavedProductsM: React.FC<SavedProductsMProps> = (props) => {
  const { isChildComponent = false, isTeamManagementUser = false, teamUserId = "", isCompany = false } = props;

  const dispatch = useDispatch();
  const saveProducts = useSelector(SavedProductsSelector);
  const location = useLocation();
  const totalProducts = useSelector(TotalProductSelector);
  const dbUser = useSelector(DbUserSelector);
  const searchQuery = useSelector(ProductSearchFilterSelector);

  const navigate = useNavigate();
  const { pageSize, setPageNumber, pageNumber, offset } = usePagination();

  const [state, setState] = useState<SavedProductsState>({
    loading: true,
    openPopper: false,
    anchorEl: null,
  });
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [singleSelectedProduct, setSingleSelectedProduct] = useState<Product | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectionMode, setSelectionMode] = useState<boolean>(false);
  const [openConfirmBox, setOpenConfirmBox] = useState<boolean>(false);
  const [openTagsSheet, setOpenTagsSheet] = useState<boolean>(false);

  // const [searchQuery, setSearchQuery] = useState<string>("");
  const [search, setSearch] = useState<string>(searchQuery);
  const [hideInternalUsersProducts, setHideInternalUsersProducts] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("Filter");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [productLiveFilter, setProductLiveFilter] = useState<any>(() => {
    const prevPublishStatus = localStorage.getItem("publishStatus");
    return prevPublishStatus ? prevPublishStatus : contentFilters.all;
  });

  const [refreshWaitingGrid, setRefreshWaitingGrid] = useState<boolean>(false);
  const companyRole = useSelector(CompanyRoleSelector);

  const brand = location?.state?.productBrand;
  /* eslint-disable react-hooks/exhaustive-deps */
  const rows = useMemo(() => {
    setHasMore(totalProducts > saveProducts?.length);
    const allProducts = [...saveProducts];
    if (allProducts?.length) {
      const selected = allProducts?.filter((p: Product) => p?.isSelected);
      setSelectedProducts([...selected]);
    }
    return allProducts;
  }, [saveProducts]);

  const getProducts = async (isReset = false, concat = false) => {
    setState({ ...state, loading: true });
    if (isReset) {
      dispatch(resetProductsDispatch());
    }

    // let filters: { offset: number; limit: number; productBrand: undefined | string, searchQuery: string } = {
    let filters: any = {
      offset: isReset ? 0 : offset,
      limit: isReset ? defaultPageSize : pageSize,
      productBrand: undefined,
      searchQuery: "",
      hideInteralUsersProduct: false,
    };

    if (brand) {
      filters = {
        ...filters,
        productBrand: brand === "Others" ? undefined : brand,
      };
    }

    if (productLiveFilter) {
      filters = {
        ...filters,
        searchKey: productLiveFilter === contentFilters.all ? "" : "productLive.isProductLive",
        searchFilter: productLiveFilter === contentFilters.all ? "" : productLiveFilter,
      };
    }

    if (hideInternalUsersProducts) {
      filters = {
        ...filters,
        hideInteralUsersProduct: hideInternalUsersProducts,
      };
    }

    if (searchQuery) {
      filters = {
        ...filters,
        searchQuery,
      };
    }

    dispatch(getUserProductAction({ navigate, filters, mobile: concat, isTeamManagementUser, teamUserId, isCompany }))
      .then(() => {
        setState({ ...state, loading: false, openPopper: false });
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
        setState({ ...state, loading: false, openPopper: false });
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      dispatch(setProductSearchResetDispatch());
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(resetProductsDispatch());
  }, [productLiveFilter, hideInternalUsersProducts]);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setState({ ...state, loading: true });
    getProducts(false, true);
  }, [pageNumber, pageSize, offset, productLiveFilter, hideInternalUsersProducts]);

  const isInitialRender = useRef(true); // Using a ref to track initial render

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isInitialRender.current) {
      setState({ ...state, loading: true });
      getProducts(true, true);
    } else {
      isInitialRender.current = false;
    }
  }, [searchQuery]);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (selectionMode) {
      dispatch(handleSelectAllProductActionM(saveProducts, false));
    }
  }, [selectionMode]);
  const navigateFetchAsinPage = () => {
    navigatePage(URL_FETCH_ASIN, navigate);
  };
  const fetchMore = () => {
    if (totalProducts > saveProducts.length) {
      setPageNumber(pageNumber + 1);
    }
  };

  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const handleCsvExport = (exportType: string) => {
    if (!csvLoading) {
      setCsvLoading(true);
      downloadSavedProductCsv({
        showProtectedFields: showUserEmailInProductsPage(dbUser?.role),
        brand,
        exportType,
        teamUserID: teamUserId ? teamUserId : isTeamManagementUser ? dbUser._id : null,
        isCompany:
          isCompany ||
          (dbUser?.companyID &&
            (dbUser?.role === RolesEnum.USER || dbUser?.role === RolesEnum.COMPANY_ADMIN) &&
            !teamUserId &&
            !brand),

        companyID: isCompany
          ? teamUserId
          : dbUser?.companyID && (dbUser?.role === RolesEnum.USER || dbUser?.role === RolesEnum.COMPANY_ADMIN)
          ? dbUser?.companyID
          : "",
      })
        .then(() => {
          toast.success(SUCC_CSV_EXPORT);
          setCsvLoading(false);
          setState({ ...state, openPopper: false });
        })
        .catch(() => {
          setCsvLoading(false);
          setState({ ...state, openPopper: false });
        });
    } else {
      toast.warn("Exporting Data. Please Wait");
    }
  };

  const handleSelectedCsvExport = (exportType: string) => {
    if (!csvLoading) {
      let productAsinsAndUserIds: any[] = [];
      if (singleSelectedProduct) {
        productAsinsAndUserIds = [
          {
            productASIN: singleSelectedProduct.productASIN,
            userID: singleSelectedProduct.userID,
          },
        ];
      } else {
        productAsinsAndUserIds = selectedProducts.map((product) => ({
          productASIN: product.productASIN,
          userID: product.userID,
        }));
      }
      setCsvLoading(true);
      downloadSelectedProductsCsv({
        payload: productAsinsAndUserIds,
        exportType: exportType,
        showProtectedFields: showUserEmailInProductsPage(dbUser?.role),
        bulletPointsFormat: dbUser?.bulletPointsFormat,
      })
        .then(() => {
          toast.success("CSV export successful.");
          setCsvLoading(false);
          setSelectedProducts([]);
          setSingleSelectedProduct(null);
        })
        .catch((error: any) => {
          toast.error(errorHandler(error));
          setCsvLoading(false);
          setSingleSelectedProduct(null);
        });
    } else {
      toast.warn("Exporting Data. Please Wait");
    }
  };

  const handleExportAsinTypeClick = (exportType: string) => {
    setOpenExportDialog(false);
    handleSelectedCsvExport(exportType);
  };

  const enableSelectionMode = () => {
    setSelectionMode(true);
    setState({
      ...state,
      anchorEl: null,
      openPopper: false,
    });
  };
  const disableSelectionMode = () => setSelectionMode(false);
  const handleProductLive = async () => {
    try {
      if (selectedProducts?.length) {
        const approved = selectedProducts?.filter((pro) => pro?.status === ContentStatusEnum.APPROVED);

        if (approved.length <= checkLiveProductLimit) {
          setState({ ...state, loading: true });
          await generateProductApis.checkProductsLive(
            approved.map((p: Product) => ({ productASIN: p?.productASIN || "", userID: p?.userID || "" }))
          );
          await getProducts(true);
          setState({ ...state, loading: false });
          setSelectionMode(false);
        } else {
          toast.warn(`Cannot select more than ${checkLiveProductLimit} products`);
        }
      } else toast.warn("No rows selected");
    } catch (error) {
      errorHandler(error);
    }
  };
  const handleDeleteAll = async () => {
    try {
      setOpenConfirmBox(false);
      setState({ ...state, loading: true });
      await generateProductApis.deleteGeneratedProducts(selectedProducts.map((p: Product) => p._id));
      await getProducts(true);
      setState({ ...state, loading: false });
      setSelectionMode(false);
      toast.success("Your selected products has been deleted");
    } catch (e) {
      errorHandler(e);
      setState({ ...state, loading: false });
      setSelectionMode(false);
      setOpenConfirmBox(false);
    }
  };
  const handleSelectAll = () => {
    dispatch(handleSelectAllProductActionM(saveProducts, selectedProducts?.length !== saveProducts?.length));
  };
  const handleOpenConfirmBox = () => {
    if (selectedProducts.length) {
      setOpenConfirmBox(true);
    } else {
      toast.warn("No Product Selected");
    }
  };
  const closeConfirmBox = () => setOpenConfirmBox(false);
  // const openTagsBottomSheet = () => setOpenTagsSheet(true);
  const closeTagsBottomSheet = () => setOpenTagsSheet(false);

  const handleBack = () => {
    navigatePage(`${URL_PRODUCTS}`, navigate);
  };

  // toolbar actions

  const debouncedSearch = useRef(
    debounce((value: string) => {
      // setSearchQuery(value);
      dispatch(setProductSearchFilterDispatch(value));
    }, 1500)
  ).current;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleFilterChange = (value: string, showInternalProduct: boolean) => {
    if (localStorage.getItem("Filter") || localStorage.getItem("publishStatus")) {
      localStorage.removeItem("Filter");
      localStorage.removeItem("publishStatus");
    }

    try {
      setHideInternalUsersProducts(showInternalProduct);
      localStorage.setItem("Filter", JSON.stringify(showInternalProduct));
      localStorage.setItem("publishStatus", value);
      const filter: string = value;
      setProductLiveFilter(filter);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleRefresh = () => {
    setRefreshWaitingGrid(true);
    getProducts(true, true);
  };

  const handleSingleProductCsvExport = (product: Product) => {
    setSingleSelectedProduct(product);
    setOpenExportDialog(true);
  };

  return (
    <div className={"d-flex flex-column h-auto p-2 mt-2"} style={{ marginBottom: "95px" }}>
      <div className="d-flex flex-column  ms-2 ">
        {selectionMode ? (
          <>
            <div className={"d-flex justify-content-between align-items-center"}>
              <div onClick={handleSelectAll}>
                <MTypography variant={"h4"} customClass={classes.SelectionOptions}>
                  {selectedProducts?.length === saveProducts?.length ? "Unselect All" : "Select All"}
                </MTypography>
              </div>

              <div className={"p-1"} onClick={disableSelectionMode}>
                <MTypography variant={"h4"} customClass={classes.SelectionOptions}>
                  {"Cancel"}
                </MTypography>
              </div>
            </div>
            <div className={"mt-4 mb-1"}>
              <MTypography variant={"h4"}>{`${
                saveProducts?.filter((p: Product) => p?.isSelected)?.length
              } Selected  `}</MTypography>
            </div>
          </>
        ) : (
          <>
            <div
              className={
                !isChildComponent ? "d-flex align-items-center justify-content-between" : "d-flex justify-content-end"
              }
            >
              <div
                className={
                  !isChildComponent
                    ? "d-flex w-100 justify-content-between align-items-center"
                    : "d-flex justify-content-end w-100"
                }
              >
                {!isChildComponent && !isChildComponent && !isTeamManagementUser && (
                  <>
                    <div onClick={handleBack}>
                      <img src={backIcon} alt={"back-icon"} />
                    </div>
                    <div className={"p-1"}>
                      <MButton
                        size={"small"}
                        variant="contained"
                        rootClass={classes.savedProductsAddButtonM}
                        onClick={navigateFetchAsinPage}
                      >
                        {"Add"}
                      </MButton>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!isChildComponent && !isChildComponent && !isTeamManagementUser && (
              <div>
                <MTypography variant={"h4"}>{capitalizeEachLetter(brand || "products")}</MTypography>
              </div>
            )}
            {!isChildComponent && !isChildComponent && !isTeamManagementUser && (
              // this counter will show on Brand's asins view
              <ProductStatusCounter />
            )}
            <AsinsViewToolbarM
              state={state}
              setState={setState}
              handleCsvExport={() => handleCsvExport("row")}
              enableSelectionMode={enableSelectionMode}
              handleSearchChange={handleChange}
              search={search}
              handleFilterChange={handleFilterChange}
              hideInternalUsersProducts={hideInternalUsersProducts}
              productLiveFilter={productLiveFilter}
              handleRefresh={handleRefresh}
              hideProductVisibilityFilter={!!teamUserId}
            />
          </>
        )}
      </div>
      {csvLoading && <div className={"d-flex align-items-centers justify-content-center p-3"}>Exporting CSV...</div>}
      {(!isTeamManagementUser || showWaitingGridOnClientsForCompany(dbUser?.role)) && !brand && (
        <WaitingGrid refreshWaitingGrid={refreshWaitingGrid} setRefreshWaitingGrid={setRefreshWaitingGrid} />
      )}
      <InfiniteScroll
        dataLength={saveProducts.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={
          <div className={"d-flex justify-content-center my-3"}>
            {state.loading && <CircularProgress color={"primary"} />}
          </div>
        }
      >
        <div>
          {rows.length > 0 ? (
            rows.map((product: Product) => {
              return (
                <ProductDetailCard
                  getProducts={getProducts}
                  key={product._id}
                  productDetail={product}
                  tab={TabsEnum.SAVED}
                  selectionMode={selectionMode}
                  isRerun
                  isChildComponent={isChildComponent}
                  isTeamManagementUser={isTeamManagementUser}
                  handleExportCsvClick={() => handleSingleProductCsvExport(product)}
                />
              );
            })
          ) : state.loading ? (
            <AsinsCardAnimation />
          ) : (
            <>{!state.loading && <NoResultsFound pt={4} />}</>
          )}
        </div>
      </InfiniteScroll>

      {selectionMode && (
        <BottomNavigation className={`${classes.BottomNavigation}`}>
          <div className={`d-flex align-items-start justify-content-center gap-4 mt-2 w-100 p-3`}>
            {showLiveCheckButton(dbUser?.role, ContentStatusEnum.APPROVED) && (
              <MTypography
                variant={"h4"}
                customClass={`${classes.SelectionOptions} ${classes.BottomNavigationOptions}`}
              >
                <div onClick={handleProductLive} className={"d-flex align-items-center cursor-pointer flex-column"}>
                  <OnlinePredictionOutlinedIcon className={classes.ToolbarIcon} />
                  {"Product Live"}
                </div>
              </MTypography>
            )}

            {showAddTagOption(dbUser?.role) && (
              <MTypography
                variant={"h4"}
                customClass={`${classes.SelectionOptions} ${classes.BottomNavigationOptions}`}
              >
                {/*<div onClick={openTagsBottomSheet} className={"d-flex align-items-center cursor-pointer flex-column"}>*/}
                {/*  <LocalOfferOutlinedIcon className={classes.ToolbarIcon} />*/}
                {/*  {"Add a Tag"}*/}
                {/*</div>*/}
                ""
              </MTypography>
            )}

            {(showMultipleProductDeleteButton(dbUser?.role) ||
              showMultipleProductDeleteButtonCompany(dbUser?.role, companyRole)) && (
              <MTypography
                variant={"h4"}
                customClass={`${classes.SelectionOptions} ${classes.BottomNavigationOptions}`}
              >
                <div onClick={handleOpenConfirmBox} className={"d-flex align-items-center cursor-pointer flex-column"}>
                  <DeleteIcon className={classes.ToolbarIcon} />
                  {"Delete"}
                </div>
              </MTypography>
            )}
            {!isExportButton(dbUser?.role, dbUser?.companyID, companyRole) && (
              <MTypography
                variant={"body2"}
                customClass={`${classes.SelectionOptions} ${classes.BottomNavigationOptions}`}
              >
                <div
                  onClick={() => setOpenExportDialog(true)}
                  className={"d-flex align-items-center cursor-pointer flex-column"}
                >
                  <FileDownloadOutlinedIcon fontSize={"medium"} color="primary" />
                  {"Export"}
                </div>
              </MTypography>
            )}
          </div>
        </BottomNavigation>
      )}

      <MBottomSheet open={openTagsSheet} showFullHeight>
        <TagPopper onClose={closeTagsBottomSheet} selectedProducts={selectedProducts} />
      </MBottomSheet>
      <ConfirmBox
        open={openConfirmBox}
        confirm={handleDeleteAll}
        cancel={closeConfirmBox}
        onClose={closeConfirmBox}
        title="Confirm Delete Products"
        confirmButtonText="Delete Selected"
      >
        {"Do you want to delete selected products?"}
      </ConfirmBox>

      <ExportAsinsDialoge
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        handleTypeClick={handleExportAsinTypeClick}
        title="Export"
      />
    </div>
  );
};

export default SavedProductsM;
